import { useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { Checkbox, Table } from 'semantic-ui-react';

import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

interface JiraProjectModalTableToggleRowProps {
  jiraProjectModalFormField: JiraProjectModalFormFields;
  name: ReactNode;
  description: ReactNode;
}

const JiraProjectModalTableToggleRow = ({
  jiraProjectModalFormField,
  name,
  description,
}: JiraProjectModalTableToggleRowProps) => {
  const { setFieldValue, values } = useFormikContext<JiraProjectModalFormValues>();
  const fieldValue = Boolean(values[jiraProjectModalFormField]);

  const onToggle = () => {
    setFieldValue(jiraProjectModalFormField, !fieldValue);
  };

  const onReset = () => {
    setFieldValue(jiraProjectModalFormField, false);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={<span>{name}</span>} />
      <Table.Cell>
        <div className="jira-project-modal__content-sync-settings-toggle-container">
          <Checkbox checked={fieldValue} onChange={onToggle} />
          <div>{description}</div>
        </div>
      </Table.Cell>
      <JiraProjectModalTableDeleteCell onClick={onReset} enabled={fieldValue} />
    </JiraProjectModalTableRow>
  );
};

export default JiraProjectModalTableToggleRow;
