import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb, Button, Popup } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { writeAcademyUrlToClipboard } from 'features/academy/helpers';
import { getCourseForId, getLessonForId, getTrackForId } from 'features/academy/selectors';
import { CopyLinkText } from 'features/common/buttons/portable_links/portable_link_button';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { graduationCapSolid, paperPlaneLight as itemLink, LpIcon } from 'features/common/lp_icon';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

interface AcademyModalHeaderParams {
  courseId: string;
  trackId: string;
  lessonId: string;
}

const AcademyModalBreadcrumbs = ({ courseId, trackId }: AcademyModalHeaderParams) => {
  const course = useSelector((state) => getCourseForId(state, courseId));
  const track = useSelector((state) => getTrackForId(state, trackId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const sections = useMemo(() => {
    return course && track
      ? [
          {
            as: LpLink,
            key: 'academy',
            content: 'Academy',
            to: frontend.academy.url({ organizationId, workspaceId }),
          },
          {
            as: LpLink,
            key: course.id + course.title,
            content: course.title,
            to: frontend.track.url({ organizationId, workspaceId, courseId, trackId }),
          },
          { key: track.id, content: track.title },
        ]
      : [];
  }, [course, courseId, organizationId, track, trackId, workspaceId]);

  return <Breadcrumb className="segment-inset" divider="/" size="big" sections={sections} />;
};

const AcademyModalHeader = ({ courseId, trackId, lessonId }: AcademyModalHeaderParams) => {
  const course = useSelector((state) => getCourseForId(state, courseId));
  const track = useSelector((state) => getTrackForId(state, trackId));
  const lesson = useSelector((state) => getLessonForId(state, lessonId));
  const [copyLinkText, setCopyLinkText] = useState(CopyLinkText.Copy);
  const [isOpen, setIsOpen] = useState(false);
  const afterLinkClick = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const copyAcademyUrl = useCallback(() => {
    const lessonTitle = lesson?.title ?? (
      <>
        <ProductName /> Academy Lesson
      </>
    );
    const clipboardRes = writeAcademyUrlToClipboard({
      courseId,
      trackId,
      lessonId,
      title: lessonTitle,
    });

    if (afterLinkClick.current) {
      clearTimeout(afterLinkClick.current);
    }
    if (clipboardRes) {
      setCopyLinkText(CopyLinkText.Copied);
    } else {
      setCopyLinkText(CopyLinkText.Failed);
    }

    afterLinkClick.current = setTimeout(() => {
      setCopyLinkText(CopyLinkText.Copy);
      setIsOpen(false);
    }, 1000);
  }, [courseId, lesson?.title, lessonId, trackId]);

  const openPopupCallback = () => {
    setIsOpen(true);
  };

  const closePopupCallback = () => {
    if (copyLinkText === CopyLinkText.Copy) {
      setCopyLinkText(CopyLinkText.Copy);
      setIsOpen(false);
    }
  };

  if (!course || !track || !lesson) {
    return null;
  }

  return (
    <>
      <div className="lp-academy-modal__header-breadcrumbs">
        <LpIcon icon={graduationCapSolid} />
        <AcademyModalBreadcrumbs courseId={courseId} trackId={trackId} lessonId={lessonId} />
      </div>
      <div className="lp-academy-modal__header-actions">
        <Popup
          transition="false"
          trigger={
            <Button className="icon" size="small" onClick={copyAcademyUrl}>
              <LpIcon icon={itemLink} />
            </Button>
          }
          content={copyLinkText}
          open={isOpen}
          basic
          position={'bottom center'}
          size="tiny"
          on="hover"
          onOpen={openPopupCallback}
          onClose={closePopupCallback}
        />
      </div>
    </>
  );
};

export default AcademyModalHeader;
