import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { InvitationState } from 'daos/enums';
import { InvitationDao } from 'daos/invitation';
import { Invitation } from 'daos/model_types';
import { AuthType } from 'features/authentication/enums';
import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import { ClickHereToLogin } from 'features/authentication/unauthenticated/token/click_here_to_login';
import InvitationForm from 'features/authentication/unauthenticated/token/invitation/form';
import {
  InvitationError,
  InvitationErrorMessage,
} from 'features/authentication/unauthenticated/token/invitation/invitation_error';
import { InvitationExpiredOrRevoked } from 'features/authentication/unauthenticated/token/invitation/invitation_expired_or_revoked';
import { joinEntityNames } from 'features/authentication/unauthenticated/token/invitation/join_entity_names';
import { setCurrentUserId } from 'features/common/current/slice';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { LpLogo } from 'features/shared/components/lp_logo';
import { awaitRequestFinish } from 'lib/api';
import { url } from 'lib/url_builder';
import { defaultLandingPage, frontend } from 'lib/urls';
import { getOrganizationsById } from 'redux/entities/selectors/organization';
import { getUsersById } from 'redux/entities/selectors/user';
import { getWorkspacesById } from 'redux/entities/selectors/workspace';

import './index.scss';
import './invitation_edge_cases.scss';

interface AcceptInvitationProps {
  token: string;
  frontloadData: ({
    organizations,
    providedOrgId,
    providedWsId,
    callbackOnFrontloadDataComplete,
  }: FrontloadDataProps) => void;
}

interface SentAcceptedOrRevokedProps extends AcceptInvitationProps {
  invitation?: Invitation;
  setPatchComplete: Dispatch<SetStateAction<boolean>>;
  invitationError?: InvitationError;
}

const SentAcceptedOrRevoked = ({
  invitation,
  token,
  frontloadData,
  setPatchComplete,
  invitationError,
}: SentAcceptedOrRevokedProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector(getUsersById) ?? {};
  const workspaces = useSelector(getWorkspacesById);
  const workspaceNames = workspaces ? Object.values(workspaces).map((workspace) => workspace.name) : [];

  const organizations = useSelector(getOrganizationsById);
  const organizationNames = organizations ? Object.values(organizations).map((organization) => organization.name) : [];

  if (invitationError) {
    return <InvitationErrorMessage error={invitationError} />;
  }

  if (!invitation) {
    return null;
  }

  const isSsoInvite = invitation.authType === AuthType.SAML;
  const invitationCreator = users[invitation.createdBy.id];

  const buildUrlAfterSuccess = ({
    organizationId,
    workspaceId,
    dashboardId,
  }: {
    organizationId: number;
    workspaceId?: number;
    dashboardId?: number;
  }) => {
    if (!workspaceId) {
      return frontend.authenticated.url({ organizationId });
    }

    if (dashboardId) {
      return frontend.dashboardGuest.url({ organizationId, workspaceId, dashboardId });
    }

    return defaultLandingPage.url({ organizationId, workspaceId });
  };

  const lpAuthLoginAfterSuccess = (invitation: Invitation) => {
    const receiverId = invitation.receiver?.id;
    const organizationId = invitation.organization.id;
    const workspaceId = invitation.workspaces[0]?.id;
    const dashboardId = invitation.dashboards[0]?.id;

    if (workspaceId && receiverId) {
      setPatchComplete(true);
      dispatch(setCurrentUserId(receiverId));
      frontloadData({
        providedOrgId: organizationId,
        providedWsId: workspaceId,
        callbackOnFrontloadDataComplete: () => {
          history.push(buildUrlAfterSuccess({ organizationId, workspaceId, dashboardId }));
        },
      });
    }
  };

  const ssoLoginAfterSuccess = (invitation: Invitation) => {
    const organizationId = invitation.organization.id;
    const workspaceId = invitation.workspaces[0]?.id;
    const dashboardId = invitation.dashboards[0]?.id;

    const samlIdpUrl = url(`${invitation.samlUrl}`);
    const relocationUrl = samlIdpUrl.url(
      {},
      {
        query: { RelayState: buildUrlAfterSuccess({ organizationId, workspaceId, dashboardId }) },
      }
    );
    window.location.assign(relocationUrl);
  };

  const inviteOnSuccess = isSsoInvite ? ssoLoginAfterSuccess : lpAuthLoginAfterSuccess;

  switch (invitation.state) {
    case InvitationState.Expired:
    case InvitationState.Revoked:
      return <InvitationExpiredOrRevoked />;
    case InvitationState.Accepted:
      return (
        <div className="invitation-already-accepted">
          <span>Invitation has already been accepted.</span>
          <ClickHereToLogin />
        </div>
      );
    case InvitationState.Sent:
      return (
        <>
          <div className="lp-invitation">
            <span className="lp-invitation__logo-block">
              <LpLogo className="lp-invitation__logo" />
            </span>
            <div className="lp-invitation__invitation-details">
              {invitationCreator ? (
                <>{invitationCreator.email} has invited you to join</>
              ) : (
                'You have been invited to join'
              )}{' '}
              {joinEntityNames(workspaceNames).map((element, index) => (
                <span key={index}>{element}</span>
              ))}
              {' within '}
              {joinEntityNames(organizationNames).map((element, index) => (
                <span key={index}>{element}</span>
              ))}
            </div>

            <InvitationForm
              inviteeEmail={invitation.email}
              hasExistingAccount={!!invitation?.receiver}
              onSuccess={inviteOnSuccess}
              invitationCreatorEmail={invitationCreator?.email}
              invitationCreatorFirstAndLastName={`${invitationCreator?.firstName ?? 'unknown'} ${
                invitationCreator?.lastName ?? ''
              }`}
              invitationId={invitation.id}
              token={token}
              isSsoInvite={isSsoInvite}
            />
          </div>

          <LpLegalTermsFooter />
        </>
      );
  }
};

export const AcceptInvitation = ({ token, frontloadData }: AcceptInvitationProps) => {
  const dispatch = useDispatch();

  const [invitation, setInvitation] = useState<Invitation | undefined>();

  const [fetchComplete, setFetchComplete] = useState(false);
  const [patchComplete, setPatchComplete] = useState(false);
  const [invitationError, setInvitationError] = useState<InvitationError>();

  useEffect(() => {
    if (token.length) {
      const { uuid } = dispatch(
        InvitationDao.get({
          token,
          include: {
            includeCreatedBy: true,
            includeReceiver: true,
            includeWorkspace: true,
          },
        })
      );

      dispatch(
        awaitRequestFinish<Invitation>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setInvitationError({
                code: errors[0].code,
                detail: errors[0].status === 404 ? 'not found' : errors[0].detail,
                status: errors[0].status,
              });
            }
          },
          onFinish: () => setFetchComplete(true),
          onSuccess: ({ data }) => setInvitation(data),
        })
      );
    }
  }, [dispatch, token]);

  return !fetchComplete || patchComplete ? (
    <LpOverlayLoader />
  ) : (
    <SentAcceptedOrRevoked
      invitation={invitation}
      token={token}
      frontloadData={frontloadData}
      setPatchComplete={setPatchComplete}
      invitationError={invitationError}
    />
  );
};
