import { jira, LpIcon } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { useHasSystemFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { ProductName } from 'lib/use_product_name';

export const Legend = () => {
  const hasTempoBranding = useHasSystemFeature(FeatureFlag.tempoBranding);
  return (
    <div className="jira-project-modal__content-legend">
      <div className="jira-project-modal__content-legend-item">
        <LpIcon icon={jira} size="lg" />
        Jira Field
      </div>
      <div className="jira-project-modal__content-legend-item">
        <LpSvg
          icon={hasTempoBranding ? LpSvgs.PORTFOLIO_MANAGER_WAVY : LpSvgs.LP_WAVY}
          size={hasTempoBranding ? 17 : 15}
        />
        <ProductName /> Field
      </div>
      <div className="jira-project-modal__content-legend-item">
        <LpSvg icon={LpSvgs.JIRA_BLUE_GRADIENT} size={17} />
        Jira Field Required For Syncing New Tasks
      </div>
    </div>
  );
};
