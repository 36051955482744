import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import { useHasSystemFeature } from 'hooks/use_has_feature';
import { LIQUIDPLANNER_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';
import { FeatureFlag } from 'lib/feature_flags';
import lpLogo from 'static/img/lpSvgLogoWhite.svg';
import pmLogo from 'static/img/portfolio-manager-logo.svg';

export const LpLogo = ({ className }: { className?: string }) => {
  const hasTempoBranding = useHasSystemFeature(FeatureFlag.tempoBranding);

  return (
    <NewWindowLink to={LIQUIDPLANNER_URL}>
      <Image src={hasTempoBranding ? pmLogo : lpLogo} className={classNames('lp-logo', 'clickable', className)} />
    </NewWindowLink>
  );
};
