import { useFormikContext } from 'formik';
import { useState, useEffect, useMemo } from 'react';

import { JiraParentAutocompleteDropdown } from 'features/jira_project/modal/sections/project_and_issues/jira_parent_autocomplete_dropdown';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

const getKey = (index: number, parentIssueIdsOrKeys: Array<string>) => {
  const value = parentIssueIdsOrKeys[index];
  return value ? `${index}-${value}` : `${index}-empty`;
};

const ParentFiltersMultiInput = () => {
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const parentIssueIdsOrKeys = useMemo(() => values[JiraProjectModalFormFields.ParentIssueIdsOrKeys] || [], [values]);
  const [dropdowns, setDropdowns] = useState<Array<number>>([0]);

  useEffect(() => {
    const newDropdowns =
      parentIssueIdsOrKeys.length === 0 ? [0] : Array.from({ length: parentIssueIdsOrKeys.length + 1 }, (_, i) => i);
    setDropdowns(newDropdowns);
  }, [parentIssueIdsOrKeys]);

  const handleDelete = (index: number) => {
    const newParentIssueIdsOrKeys = parentIssueIdsOrKeys.filter((_, i) => i !== index);
    setFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, newParentIssueIdsOrKeys);
  };

  const handleSelect = (suggestion: string | null, index: number) => {
    if (suggestion === null) {
      handleDelete(index);
    } else {
      const newParentIssueIdsOrKeys = [...parentIssueIdsOrKeys];
      if (index < newParentIssueIdsOrKeys.length) {
        newParentIssueIdsOrKeys[index] = suggestion;
      } else {
        newParentIssueIdsOrKeys.push(suggestion);
      }
      setFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, newParentIssueIdsOrKeys);
    }
  };

  return (
    <>
      {dropdowns.map((index) => (
        <JiraParentAutocompleteDropdown
          key={getKey(index, parentIssueIdsOrKeys)}
          index={index}
          onDelete={handleDelete}
          onSelect={handleSelect}
          selectedValues={parentIssueIdsOrKeys}
          showRemoveButton={index !== parentIssueIdsOrKeys.length}
        />
      ))}
    </>
  );
};

export default ParentFiltersMultiInput;
