import classNames from 'classnames';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Message } from 'semantic-ui-react';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import LpLink from 'containers/shared/lp_link';
import { OrganizationSsoSettingsDao, OrganizationSsoSettings } from 'daos/organization_sso_settings';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { LpFormRadio } from 'features/common/forms/lp_form_radio';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

import { ManageSsoUserModal } from './manage_user_modal';

import './manage.scss';

enum SsoSettings {
  OrgIsEnabled = 'orgIsEnabled',
  OrgIsDisabled = 'orgIsDisabled',
  AllMembersEnabled = 'allMembersEnabled',
  AllMembersDisabled = 'allMembersDisabled',
  MembersDefaultEnabled = 'membersDefaultEnabled',
  MembersDefaultDisabled = 'membersDefaultDisabled',
}

export const ManageSso = ({
  organizationSsoSettings,
  fetchSSO,
}: {
  organizationSsoSettings: OrganizationSsoSettings;
  fetchSSO: () => void;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const [showManageSsoUserModal, setShowManageSsoUserModal] = useState(false);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    isSubmitting,
    setSubmitting,
    setStatus: setErrorStatus,
    status: errorStatus,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ssoEnabled: organizationSsoSettings.ssoEnabled ? SsoSettings.OrgIsEnabled : SsoSettings.OrgIsDisabled,
      enableSsoAllMembers:
        organizationSsoSettings.enableSsoAllMembers && !organizationSsoSettings.hasMultiOrgUsers
          ? SsoSettings.AllMembersEnabled
          : SsoSettings.AllMembersDisabled,
      enableSsoNewMembersDefault: organizationSsoSettings.enableSsoNewMembersDefault
        ? SsoSettings.MembersDefaultEnabled
        : SsoSettings.MembersDefaultDisabled,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ ssoEnabled, enableSsoAllMembers, enableSsoNewMembersDefault }) => {
      setErrorStatus(undefined);
      const organizationSsoSettingsId = organizationSsoSettings.id;

      const { uuid } = dispatch(
        OrganizationSsoSettingsDao.update(
          { organizationId, organizationSsoSettingsId },
          {
            ssoEnabled: ssoEnabled === SsoSettings.OrgIsEnabled,
            enableSsoAllMembers: enableSsoAllMembers === SsoSettings.AllMembersEnabled,
            enableSsoNewMembersDefault: enableSsoNewMembersDefault === SsoSettings.MembersDefaultEnabled,
          }
        )
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: () => fetchSSO(),
          onError: ({ errors }) => {
            const error = errors[0];
            if (error) {
              setErrorStatus(`${error.title}: ${error.detail}`);
            } else {
              setErrorStatus(lpErrorText.somethingWentWrong);
            }
          },
          onFinish: () => setSubmitting(false),
        })
      );
    },
  });

  const { enableSsoAllMembers, ssoEnabled, enableSsoNewMembersDefault } = values;

  const ssoRequiredForAllUsers = enableSsoAllMembers === SsoSettings.AllMembersEnabled;
  const showMoreFields = ssoEnabled === SsoSettings.OrgIsEnabled;
  const isNewMembersDefaultEnabled = enableSsoNewMembersDefault === SsoSettings.MembersDefaultEnabled;

  useEffect(() => {
    if (ssoRequiredForAllUsers && enableSsoNewMembersDefault === SsoSettings.MembersDefaultDisabled) {
      setFieldValue('enableSsoNewMembersDefault', SsoSettings.MembersDefaultEnabled);
    }
  }, [ssoRequiredForAllUsers, enableSsoNewMembersDefault, setFieldValue]);

  const handleOpenSsoUserModal = () => setShowManageSsoUserModal(true);
  const handleCloseSsoUserModal = () => setShowManageSsoUserModal(false);
  const ssoSetupNotVerified = organizationSsoSettings.verifiedDate === null;
  const disableSsoRequirementForAllMembers = organizationSsoSettings.hasMultiOrgUsers;

  return (
    <>
      {ssoSetupNotVerified && (
        <Message>
          <Message.Header>Your SSO setup is incomplete</Message.Header>
          <p>
            You must configure your SSO and perform a successful test in the{' '}
            <LpLink
              to={frontend.singleSignOnSetup.url({
                organizationId,
              })}
            >
              Setup SSO
            </LpLink>{' '}
            tab before it can be enabled for this Organization.
          </p>
        </Message>
      )}

      <Form className="manage-sso-form" onSubmit={handleSubmit} loading={isSubmitting}>
        <Form.Field className="manage-sso-form__enable-sso" disabled={ssoSetupNotVerified}>
          <div className="manage-sso-form__label">Enable SSO for your Organization</div>

          <div className="manage-sso-form__two-column-grid">
            <LpFormRadio
              fieldKey="ssoEnabled"
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              label={'Yes'}
              value={SsoSettings.OrgIsEnabled}
            />
            <div>Enable SSO for this organization based on your SSO setup.</div>

            <LpFormRadio
              fieldKey="ssoEnabled"
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              label={'No'}
              value={SsoSettings.OrgIsDisabled}
            />
            <div>
              Disable SSO for this organization. Users will need to login with their email and password stored in
              <ProductName />.
            </div>
          </div>
        </Form.Field>

        {showMoreFields && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Form.Field className="manage-sso-form__require-all-users-sso" disabled={ssoSetupNotVerified}>
              <div className="manage-sso-form__label">Require ALL users to use SSO? </div>
              {disableSsoRequirementForAllMembers && (
                <i className="manage-sso-form__warning-text">
                  This Organization contains one or more users that also belong to other Organizations. In order to
                  require SSO for all users, they must all belong to only this organization.
                </i>
              )}
              <div className="manage-sso-form__two-column-grid">
                <LpFormRadio
                  fieldKey="enableSsoAllMembers"
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  label={'Yes'}
                  disabled={disableSsoRequirementForAllMembers}
                  value={SsoSettings.AllMembersEnabled}
                />
                <div className={classNames(disableSsoRequirementForAllMembers && 'manage-sso-form__text--disabled')}>
                  All users will be required to use SSO to authenticate (no exceptions).
                </div>

                <LpFormRadio
                  fieldKey="enableSsoAllMembers"
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  label={'No'}
                  value={SsoSettings.AllMembersDisabled}
                />
                <div>
                  This allows mixed authentications.{' '}
                  <Button
                    type="button"
                    disabled={ssoRequiredForAllUsers}
                    content={'Manage User Exceptions'}
                    onClick={handleOpenSsoUserModal}
                  />
                </div>
              </div>
            </Form.Field>
            <Form.Field className="manage-sso-form__new-user-default" disabled={ssoSetupNotVerified}>
              <div className="manage-sso-form__label">New User Default</div>

              <div className="manage-sso-form__two-column-grid">
                <LpFormRadio
                  fieldKey="enableSsoNewMembersDefault"
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  label={'SSO'}
                  value={SsoSettings.MembersDefaultEnabled}
                />
                <div>New users will be setup to use SSO when they are added to the organization.</div>

                <LpFormRadio
                  fieldKey="enableSsoNewMembersDefault"
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  disabled={ssoRequiredForAllUsers}
                  label={'LP'}
                  value={SsoSettings.MembersDefaultDisabled}
                />
                <div className={classNames(ssoRequiredForAllUsers && 'manage-sso-form__text--disabled')}>
                  New users must use an email address and password stored in <ProductName /> to login.
                </div>
              </div>
            </Form.Field>
          </motion.div>
        )}
        <Button primary type="submit" content="Save" disabled={ssoSetupNotVerified} />

        {showManageSsoUserModal && (
          <ManageSsoUserModal
            closeModal={handleCloseSsoUserModal}
            multiOrgUsersIds={organizationSsoSettings.multiOrgUsersIds}
            isNewMembersDefaultEnabled={isNewMembersDefaultEnabled}
          />
        )}

        {!!errorStatus && <LpFormError className="manage-sso-form__error-status" error={errorStatus} />}
      </Form>
    </>
  );
};
