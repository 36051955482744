import { SyntheticEvent } from 'react';

import { circleMinusLight } from 'features/common/lp_icon';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalCustomFieldColumn } from 'features/jira_project/modal/sections/field_mapping_section/jira_project_modal_custom_field_column';
import { NameColumn } from 'features/jira_project/modal/sections/field_mapping_section/name_column';
import { HandleDeleteRowClick } from 'features/jira_project/modal/sections/field_mapping_section/types';
import { useFieldMappings } from 'features/jira_project/modal/sections/field_mapping_section/use_field_mappings';
import { getNameIcon } from 'features/jira_project/modal/sections/field_mapping_section/utils/helpers';
import { FieldMappingRowType, FieldMappingValue } from 'features/jira_project/modal/types';

import 'features/jira_project/modal/jira_project_modal.scss';

interface FieldMappingRowProps {
  nameMapping: FieldMappingValue;
  nameColumnPicklistData: ReadonlyArray<FieldMappingValue>;
  onNameColumnPicklistChange?: (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => void;
  rowType: FieldMappingRowType;
  picklistData: ReadonlyArray<FieldMappingValue>;
  onDeleteRowClick?: HandleDeleteRowClick;
}

export const FieldMappingRow = ({
  nameMapping,
  rowType,
  picklistData,
  onDeleteRowClick,
  nameColumnPicklistData,
  onNameColumnPicklistChange,
}: FieldMappingRowProps) => {
  const { findFieldMappingItem, deleteFieldMappingItem } = useFieldMappings();

  const handleDeleteRowValue = () => {
    const fieldMappingItem = findFieldMappingItem({ rowId: nameMapping.id, rowType });
    fieldMappingItem && deleteFieldMappingItem({ fieldMappingItem });
  };

  const handleDeleteRow = () => {
    handleDeleteRowValue();
    onDeleteRowClick?.({ fieldType: rowType, nameMapping });
  };

  const getIsDeleteValuesButtonEnabled = () => {
    const fieldMappingItem = findFieldMappingItem({ rowId: nameMapping.id, rowType });
    return Boolean(fieldMappingItem);
  };

  return (
    <JiraProjectModalTableRow>
      <NameColumn
        onChange={onNameColumnPicklistChange}
        picklistData={nameColumnPicklistData}
        nameMapping={nameMapping}
        icon={onNameColumnPicklistChange ? undefined : getNameIcon({ rowType })}
        rowType={rowType}
      />
      <JiraProjectModalCustomFieldColumn rowType={rowType} picklistData={picklistData} nameMapping={nameMapping} />
      <JiraProjectModalTableDeleteCell enabled={getIsDeleteValuesButtonEnabled()} onClick={handleDeleteRowValue} />
      <JiraProjectModalTableDeleteCell
        enabled
        onClick={handleDeleteRow}
        icon={circleMinusLight}
        hoverText="Delete row"
      />
    </JiraProjectModalTableRow>
  );
};
