import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { TabIcon } from 'features/jira_project/modal/sections/tab_icon';
import { TabNames } from 'features/jira_project/modal/types';

interface TabNameProps {
  name: TabNames;
  completed: boolean;
  dataTestId: string;
}

const TabName = ({ name, completed, dataTestId }: TabNameProps) => {
  const { isEditMode } = useJiraProjectModalContext();
  return (
    <span className="jira-project-modal__tab">
      <div className="jira-project-modal__tab-name" data-testid={dataTestId}>
        {!isEditMode && <TabIcon completed={completed} />}
        <span>{name}</span>
      </div>
    </span>
  );
};

export default TabName;
