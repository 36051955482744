import classNames from 'classnames';
import { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { AssignmentDao } from 'daos/assignment';
import { getCurrentWorkspaceId, getCurrentOrganizationId } from 'features/common/current/selectors';
import { LpIcon, checkCircleSolid } from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

export const DoneBar = ({
  doneDate,
  hasHighEffort,
  isAncestorItemScheduleBar,
  isAssignment,
  isAssignmentTable,
  isDoneLate,
  itemId,
  showSuccessor,
  style,
}: {
  doneDate: string | null;
  hasHighEffort: boolean;
  isAncestorItemScheduleBar: boolean;
  isAssignment: boolean;
  isAssignmentTable: boolean;
  isDoneLate: boolean;
  itemId: number | undefined;
  showSuccessor: boolean;
  style: CSSProperties;
}) => {
  const dispatch = useDispatch();
  const { formatLocalDate } = useLocalizedFormats();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);

  const clearRemainingEstimate = () => {
    if (itemId) {
      dispatch(
        AssignmentDao.update(
          { organizationId, workspaceId, itemId },
          {
            id: itemId,
            highEffort: null,
            lowEffort: null,
          }
        )
      );
    }
  };

  return (
    <>
      {isAssignment ? (
        <span className="assignment-done">
          {isAssignmentTable && hasHighEffort && (
            <Button className="assignment-done__button" size="tiny" content={'Zero'} onClick={clearRemainingEstimate} />
          )}
          Done{doneDate && `: ${formatLocalDate(doneDate)}`}
        </span>
      ) : (
        <div
          className={classNames(
            'lp-schedule__bar-element',
            'item-done',
            isAncestorItemScheduleBar && 'lp-schedule__bar-element--ancestor',
            isDoneLate && 'lp-schedule__bar-element--late',
            showSuccessor && 'lp-schedule__bar-element--successor',
            showSuccessor && isAncestorItemScheduleBar && 'lp-schedule__bar-element--successor-ancestor',
            showSuccessor && isDoneLate && 'lp-schedule__bar-element--successor-late'
          )}
          style={style}
        >
          <LpIcon icon={checkCircleSolid} />
        </div>
      )}
    </>
  );
};
