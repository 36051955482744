import { Message } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

enum ResetPasswordErrors {
  Claimed = 'token has already been claimed',
  Expired = 'token has expired',
}

const ForgotPasswordLink = () => (
  <span>
    <LpLink to={frontend.forgotPassword.url({})}>Forgot Password</LpLink> page.
  </span>
);

const resetPasswordErrors = (field: string) => {
  switch (field) {
    case ResetPasswordErrors.Claimed: {
      return (
        <>
          The link is no longer valid. Request a new one on the <ForgotPasswordLink />
        </>
      );
    }

    case ResetPasswordErrors.Expired: {
      return (
        <>
          The link expired due to inactivity. Request a new one on the <ForgotPasswordLink />
        </>
      );
    }

    default: {
      return (
        <>
          Contact <ProductName /> support.
        </>
      );
    }
  }
};

export const PasswordError = ({ claimedAt, expired }: { claimedAt: string | null; expired: boolean }) => {
  if (!claimedAt && !expired) {
    return null;
  }

  const errorKey = claimedAt ? ResetPasswordErrors.Claimed : ResetPasswordErrors.Expired;

  return <Message error visible className="password-form_api-error" content={resetPasswordErrors(errorKey)} />;
};
