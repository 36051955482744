import { SetStateAction } from 'react';

import {
  CalculatedLpSystemId,
  FieldMappingRowType,
  FieldMappingValue,
  IssueTypeValue,
  JiraCustomFieldType,
  JiraProjectModalFormFieldMapping,
} from 'features/jira_project/modal/types';

export const addFieldMappingRow = ({
  data,
  rows,
  setRows,
}: {
  data: Array<FieldMappingValue>;
  rows: Set<FieldMappingValue>;
  setRows: (value: SetStateAction<Set<FieldMappingValue>>) => void;
}) => {
  const nextItem = data.find((item) => !Array.from(rows).some((addedItem) => addedItem.id === item.id));

  if (nextItem) {
    setRows(new Set([...rows, nextItem]));
  }
};

export const deleteFieldMappingRow = ({
  row,
  rows,
  setRows,
}: {
  row: FieldMappingValue;
  rows: Set<FieldMappingValue>;
  setRows: (value: React.SetStateAction<Set<FieldMappingValue>>) => void;
}) => {
  setRows(new Set(Array.from(rows).filter((item) => item.id !== row.id)));
};

export const shouldDisableAddRowButton = ({
  data,
  rows,
}: {
  data: Array<FieldMappingValue>;
  rows: Set<FieldMappingValue>;
}) => data.every((item) => Array.from(rows).some((addedItem) => addedItem.id === item.id));

export const shouldAllowTwoWaySync = ({
  nameMapping,
  rowType,
  fieldMappingItem,
}: {
  nameMapping: FieldMappingValue;
  rowType: FieldMappingRowType;
  fieldMappingItem: JiraProjectModalFormFieldMapping | undefined;
}): boolean => {
  const rowIsCalculatedSystemId = Object.values(CalculatedLpSystemId).includes(nameMapping.id as CalculatedLpSystemId);
  const rowIsMapped = fieldMappingItem !== undefined;

  const rowIsSprint = nameMapping.custom === JiraCustomFieldType.Sprint;
  const rowIsMappedJiraToLp = rowType === FieldMappingRowType.JiraToLp && rowIsMapped;

  return !rowIsSprint && (rowIsMappedJiraToLp || (rowIsMapped && !rowIsCalculatedSystemId));
};

export const getFieldChoiceId = ({
  rowType,
  fieldMappingItem,
}: {
  rowType: FieldMappingRowType;
  fieldMappingItem?: JiraProjectModalFormFieldMapping;
}) => {
  if (rowType === FieldMappingRowType.LpToJira) {
    return fieldMappingItem?.sourceFieldId ?? '';
  }
  return fieldMappingItem?.lpCustomFieldId?.toString() ?? fieldMappingItem?.lpSystemField ?? '';
};

export const getMandatoryOnCreationFor = ({
  nameMapping,
  selectedField,
}: {
  nameMapping: FieldMappingValue;
  selectedField?: FieldMappingValue;
}) => {
  if (nameMapping.isMandatoryOnCreationFor && nameMapping.isMandatoryOnCreationFor.length > 0) {
    return nameMapping.isMandatoryOnCreationFor;
  }
  return selectedField?.isMandatoryOnCreationFor || [];
};

export const findAndSortIssueTypes = (
  mandatoryOnCreationFor: ReadonlyArray<string>,
  jiraProjectMappingIssueTypes: ReadonlyArray<IssueTypeValue>
) =>
  jiraProjectMappingIssueTypes
    .filter((issueType) => mandatoryOnCreationFor.includes(issueType.id))
    .sort((a, b) => a.name.localeCompare(b.name));
