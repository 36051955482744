// Common time converters
export const WORK_HOURS_PER_DAY = 8;

export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;
const MILLISECONDS_PER_SECONDS = 1000;
export const SECONDS_IN_HOUR = MINUTES_PER_HOUR * SECONDS_PER_MINUTE;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_PER_SECONDS * SECONDS_IN_HOUR;

export const DAYS_IN_SECONDS = WORK_HOURS_PER_DAY * SECONDS_IN_HOUR;

export const INT_MAX_VALUE_IN_HOUR = 596523;

// Common MAX limits
export const MAX_LENGTH = 255;

// Data grid column width
export const COLUMN_WIDTH_S = 150;
export const COLUMN_WIDTH_M = 200;
export const COLUMN_WIDTH_L = COLUMN_WIDTH_S * 2;
export const COLUMN_WIDTH_XL = COLUMN_WIDTH_S * 3;
export const COLUMN_WIDTH_XXL = COLUMN_WIDTH_S * 4;

// Data grid cell styles
export const TEXT_ALIGN_RIGHT = 'rdg-text-right-align';
export const TEXT_ALIGN_LEFT = 'rdg-text-left-align';

// The None option exposed for dropdowns across the app
export const NONE = 'None';
export const OPTIONAL = 'Optional';
export const SELECT_ALL = 'Select All';

export const MIN_PASSWORD_LENGTH = 8;

// max assignments allowed on a task
export const MAX_ASSIGNMENTS_ALLOWED = 50;

// starting estimate and hours starting value
export const STARTING_ESTIMATE_AND_HOURS_VALUE = '0h';

// renders '...'
export const HORIZONTAL_ELLIPSIS = '\u2026';

// renders '•' also known as interpunct or middle dot
export const CIRCULAR_BULLET_POINT = '\u2022';

export const COST_CODE = 'Cost Code';
export const COST_CODES = 'Cost Codes';

export const FIRST_FETCH_CHANGE_LIMIT = 15;
export const LOAD_MORE_CHANGE_COUNT = 20;

export const CHANGES_BUTTON_TEXT = 'Show More';

export const MY_WORK_TASK_FETCH_LIMIT = 100;
export const TASK_BOARD_DEFAULT_FETCH_LIMIT = 300;
export const WIDGET_GRID_DEFAULT_FETCH_LIMIT = 300;
export const DATA_GRID_ITEM_FETCH_LIMIT = 100;
export const DATA_GRID_MAX_ITEM_SIZE_LIMIT = 1000;
export const MAX_PORTFOLIO_PACKAGES_PER_BATCH = 10;
export const TIMESHEET_ZERO_DURATION = 'PT0H';

export const KEYDOWN_DEBOUNCE_MILLIS = 500;

export const MAX_EMAIL_LENGTH = 70;

// urls
export const TOS_URL = 'https://www.liquidplanner.com/terms/';
export const PRIVACY_POLICY_URL = 'https://www.liquidplanner.com/privacy';
export const LIQUIDPLANNER_URL = 'https://www.liquidplanner.com';
export const LP_PRICING_URL = 'https://www.tempo.io/pricing/portfolio-manager';
export const LP_STATUS_URL = 'https://status.liquidplanner.com';

// external urls
export const JIRA_API_TOKEN_URL = 'https://id.atlassian.com/manage/api-tokens';

// emails
export const LP_SUPPORT_EMAIL = 'support@liquidplanner.com';

export const FILE_STORAGE_DESCRIPTION =
  'Store your documents alongside project work in a central location. Upload files to plan items, access them easily on Edit Panel & Search view.';

// query params
export const WEEK_QUERY_PARAM = 'week';
export const TIMESHEET_GROUPING_QUERY_PARAM = 'grouping';
export const EXPAND_ITEM_ID_QUERY_PARAM = 'expandItemId';

// Discussion pagination page Amount
export const PAGE_AMOUNT = 30;
export const TWO_PAGE_AMOUNT = PAGE_AMOUNT * 2;
export const LARGE_PAGINATION_SPACE_AMOUNT = 20;

export const WIDGET_ORDER_DEFAULT = 'default';

// Jira Integration
export const DEFAULT_JIRA_LOW_ESTIMATE_PERCENTAGE = -20;
export const DEFAULT_JIRA_HIGH_ESTIMATE_PERCENTAGE = 20;
