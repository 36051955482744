import LpPopUp from 'features/common/lp_popup';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { findAndSortIssueTypes } from 'features/jira_project/modal/sections/field_mapping_section/utils/row_utils';
import { pluralize } from 'lib/helpers';

export const IssueTypeTag = ({ mandatoryOnCreationFor }: { mandatoryOnCreationFor: ReadonlyArray<string> }) => {
  const { jiraProjectMappingIssueTypes } = useJiraProjectModalContext();
  const sortedIssueTypeNames = findAndSortIssueTypes(mandatoryOnCreationFor, jiraProjectMappingIssueTypes)
    .map(({ name }) => name)
    .join(', ');

  const issueTypeText = pluralize('Issue Type', mandatoryOnCreationFor.length, 'Issue Types');

  return (
    <LpPopUp
      trigger={
        <div className="jira-project-modal__content-tag">
          {issueTypeText} ({mandatoryOnCreationFor.length})
        </div>
      }
      content={
        <div>
          {issueTypeText}: {sortedIssueTypeNames}
        </div>
      }
    />
  );
};
