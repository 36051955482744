import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

enum RegistrationErrors {
  RecordNotFound = 'record_not_found',
  DuplicateValue = 'duplicate_value',
}

export const registrationErrors = (code: string, detail?: string) => {
  if (code === RegistrationErrors.RecordNotFound && detail && detail.includes('signupKeys')) {
    return (
      <>
        Please enter the signup key provided to you by <ProductName /> staff members.
      </>
    );
  } else if (code === RegistrationErrors.DuplicateValue && detail && detail.includes('email')) {
    return (
      <>
        This email address is already in our system.
        <span>
          Please <LpLink to={frontend.login.url({})}>{'Sign In'}</LpLink>.
        </span>
      </>
    );
  } else {
    return detail ? (
      detail
    ) : (
      <>
        Contact <ProductName /> support.
      </>
    );
  }
};
