import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Form } from 'semantic-ui-react';

import { OrganizationUserSlackIntegration, Organization, OrganizationUser } from 'daos/model_types';
import LpFormCheckbox from 'features/common/forms/lp_form_checkbox';
import { ProductName } from 'lib/use_product_name';

import useSlackIntegrationForOrgUser, {
  UseSlackIntegrationForOrgUserResult,
} from './use_slack_integration_for_org_user';

interface NotificationSettingsProps {
  organization: Organization;
  organizationUser: OrganizationUser;
}

const NotificationSettings = ({ organization, organizationUser }: NotificationSettingsProps) => {
  const orgHasSlackIntegration = organization.flags.hasSlackIntegration;

  const formSettings = useSlackIntegrationForOrgUser(organization.id, organizationUser.id);

  if (!orgHasSlackIntegration || !formSettings.createdAt) {
    return null;
  }

  return <NotificationSettingsForm {...formSettings} />;
};

function NotificationSettingsForm({
  isLoading,
  notifyTaskStatusChanged,
  notifyTaskAssignmentDeleted,
  notifyTaskAssignmentDone,
  notifyTaskAssignmentReopened,
  notifyTaskAssignmentAssigned,
  notifyTaskAssignmentUnassigned,
  notifyTaskDiscussionPostAdded,
  notifyTaskDiscussionPostMention,
  notifySiblingAssignmentAdded,
  notifySiblingAssignmentDone,
  notifyTaskMoved,
  notifyTaskScheduleDirectiveChanged,
  notifyTaskAllDependenciesSatisfied,
  update,
}: UseSlackIntegrationForOrgUserResult) {
  const { handleSubmit, getFieldMeta, getFieldProps, initialValues, submitForm, values } = useFormik({
    enableReinitialize: false,
    initialValues: {
      notifyTaskStatusChanged,
      notifyTaskAssignmentDeleted,
      notifyTaskAssignmentDone,
      notifyTaskAssignmentReopened,
      notifyTaskAssignmentAssigned,
      notifyTaskAssignmentUnassigned,
      notifyTaskDiscussionPostAdded,
      notifyTaskDiscussionPostMention,
      notifySiblingAssignmentAdded,
      notifySiblingAssignmentDone,
      notifyTaskMoved,
      notifyTaskScheduleDirectiveChanged,
      notifyTaskAllDependenciesSatisfied,
    },
    onSubmit: (values: Partial<OrganizationUserSlackIntegration>) => update(values),
  });

  /**
   * Save-on-change shamelessly stolen from Formik's own sample implementation.
   * See: https://github.com/jaredpalmer/formik/blob/master/examples/DebouncedAutoSave.js
   */
  const debouncedSubmit = useMemo(() => debounce(submitForm, 1000), [submitForm]);

  useEffect(() => {
    if (initialValues !== values) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, initialValues, values]);

  return (
    <div>
      <AnimatePresence>
        {!isLoading && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h3>Adjust your personal notification preferences</h3>
            <Form onSubmit={handleSubmit} loading={isLoading}>
              <div className="slack-integration-configuration__notification-settings-form">
                <h4 className="slack-integration-configuration__notification-settings-form__heading">My Task</h4>
                <ul className="slack-integration-configuration__notification-settings-form__checkbox-group">
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskStatusChanged"
                      label="Status changed"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskMoved"
                      label="Moved to a different location"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskScheduleDirectiveChanged"
                      label="Schedule Priority changed"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifySiblingAssignmentAdded"
                      label="New Assignment added"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifySiblingAssignmentDone"
                      label="Assignment marked done"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskAllDependenciesSatisfied"
                      label="All Predecessor Dependencies satisfied"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                </ul>
                <h4 className="slack-integration-configuration__notification-settings-form__heading">My Assignment</h4>
                <ul className="slack-integration-configuration__notification-settings-form__checkbox-group">
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskAssignmentAssigned"
                      label="Added"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskAssignmentUnassigned"
                      label="Removed (i.e., reassigned)"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskAssignmentDeleted"
                      label="Deleted"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskAssignmentDone"
                      label="Marked Done"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskAssignmentReopened"
                      label="Made Active again"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                </ul>

                <h4 className="slack-integration-configuration__notification-settings-form__heading">My Discussions</h4>
                <ul className="slack-integration-configuration__notification-settings-form__checkbox-group">
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskDiscussionPostMention"
                      label="You are mentioned in a discussion post"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                  <li>
                    <LpFormCheckbox
                      fieldKey="notifyTaskDiscussionPostAdded"
                      label="Post added to a discussion you follow"
                      getFieldProps={getFieldProps}
                      getFieldMeta={getFieldMeta}
                    />
                  </li>
                </ul>
              </div>
              <ul className="slack-integration-configuration__notices">
                <li>
                  You need Project Observer access (or above) on the plan item in order to receive change notifications,
                  or be notified about discussion posts.
                </li>
                <li>
                  <ProductName /> sends notifications for tasks and assignment changes when you have at least one active
                  task assignment.
                </li>
                <li>You won&rsquo;t be notified about changes you make yourself or discussions you post.</li>
              </ul>
            </Form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default NotificationSettings;
