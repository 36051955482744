import { useFormikContext } from 'formik';
import { FormEvent, SyntheticEvent } from 'react';
import { CheckboxProps, Form } from 'semantic-ui-react';

import { TaskPushSetting } from 'daos/external_integration_enums';
import { JiraProjectModalFieldMappingPicklist } from 'features/jira_project/modal/common/jira_project_modal_field_mapping_picklist';
import { JiraProjectModalTablePicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_picklist_cell';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { IssueTypeTag } from 'features/jira_project/modal/sections/field_mapping_section/issue_type_tag';
import { useFieldMappings } from 'features/jira_project/modal/sections/field_mapping_section/use_field_mappings';
import { FieldMappingRowType, FieldMappingValue, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

import { getFieldChoiceId, getMandatoryOnCreationFor, shouldAllowTwoWaySync } from './utils/row_utils';

interface JiraProjectModalCustomFieldColumnProps {
  picklistData: ReadonlyArray<FieldMappingValue>;
  nameMapping: FieldMappingValue;
  rowType: FieldMappingRowType;
}

export const JiraProjectModalCustomFieldColumn = ({
  picklistData,
  nameMapping,
  rowType,
}: JiraProjectModalCustomFieldColumnProps) => {
  const hasJiraIssueCreationEnabled = useHasFeature(FeatureFlag.jiraIssueCreation);
  const { canModify } = useJiraProjectModalContext();
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const { updateFieldMappingItem, findFieldMappingItem, createFieldMappingItem, deleteFieldMappingItem } =
    useFieldMappings();
  const fieldMappingItem = findFieldMappingItem({ rowId: nameMapping.id, rowType });
  const { taskPushSetting } = values;
  const hasManualPushSetting = taskPushSetting === TaskPushSetting.Manual;

  const handleFieldChange = (_: SyntheticEvent, { value: picklistChoiceId }: { value: string | undefined }) => {
    if (!picklistChoiceId) {
      fieldMappingItem && deleteFieldMappingItem({ fieldMappingItem });
    } else {
      fieldMappingItem
        ? updateFieldMappingItem({ row: nameMapping, rowType, fieldId: picklistChoiceId })
        : createFieldMappingItem({ row: nameMapping, rowType, fieldId: picklistChoiceId });
    }
  };

  const fieldId = getFieldChoiceId({ rowType, fieldMappingItem });

  const handleCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    updateFieldMappingItem({
      row: nameMapping,
      rowType,
      isTwoWaySyncChecked: data.checked,
      fieldId,
    });
  };

  const shouldShowTwoWaySync = shouldAllowTwoWaySync({
    nameMapping,
    rowType,
    fieldMappingItem,
  });

  const selectedField = picklistData.find((field) => field.id === fieldId);

  const mandatoryOnCreationFor = getMandatoryOnCreationFor({ nameMapping, selectedField });
  const shouldShowIssueTypeTag =
    hasJiraIssueCreationEnabled && hasManualPushSetting && mandatoryOnCreationFor.length > 0;

  return (
    <JiraProjectModalTablePicklistCell
      picklist={
        <JiraProjectModalFieldMappingPicklist
          picklist={picklistData}
          onChange={handleFieldChange}
          disabled={!canModify}
          rowType={rowType}
          nameMapping={nameMapping}
          isNameColumn={false}
          showNoneOption
          fieldChoiceId={fieldId}
        />
      }
      checkbox={
        shouldShowTwoWaySync && (
          <Form.Checkbox
            checked={fieldMappingItem?.isPull && fieldMappingItem.isPush}
            onChange={handleCheckboxChange}
            disabled={!canModify}
          />
        )
      }
      checkboxText={shouldShowTwoWaySync ? 'Enable Two-Way Sync' : ''}
      secondaryElement={shouldShowIssueTypeTag && <IssueTypeTag mandatoryOnCreationFor={mandatoryOnCreationFor} />}
      renderSecondaryElementInline
    />
  );
};
