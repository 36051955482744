import { useFormikContext } from 'formik';
import { throttle } from 'lodash';
import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { JiraAutoCompleteField, JiraSuggestionsDao } from 'daos/jira_suggestions';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { renderDeleteIcon } from 'features/jira_project/modal/helpers';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  JiraAutoCompleteResult,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  Suggestion,
} from 'features/jira_project/modal/types';
import { awaitRequestFinish } from 'lib/api';

interface JiraParentAutocompleteDropdownProps {
  index: number;
  onDelete: (index: number) => void;
  onSelect: (suggestion: string | null, index: number) => void;
  selectedValues: Array<string>;
  showRemoveButton: boolean;
}

const stripHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const noneOption: Suggestion = { key: 'none', text: 'None', value: '', content: 'None' };

export const JiraParentAutocompleteDropdown = ({
  index,
  onDelete,
  onSelect,
  selectedValues,
  showRemoveButton,
}: JiraParentAutocompleteDropdownProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const { setFormError, integrationId: externalIntegrationId } = useJiraProjectModalContext();
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const parentIssueIdsOrKeys = values[JiraProjectModalFormFields.ParentIssueIdsOrKeys] || [];

  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([noneOption]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = parentIssueIdsOrKeys[index];

  const fetchSuggestions = useCallback(
    async (value: string) => {
      const jiraSuggestionsFetchFn = JiraSuggestionsDao.fetchJiraSuggestions(
        { organizationId, workspaceId },
        {
          field: JiraAutoCompleteField.Parent,
          fieldValue: value ?? '',
          cloudId: values[JiraProjectModalFormFields.CloudId],
          oauthCredentialsId: values[JiraProjectModalFormFields.OauthCredentialsId],
          externalIntegrationId,
        }
      );

      const { uuid } = dispatch(jiraSuggestionsFetchFn);

      dispatch(
        awaitRequestFinish<Array<JiraAutoCompleteResult>>(uuid, {
          onSuccess: ({ data: suggestions }) => {
            const formattedSuggestions: Array<Suggestion> = suggestions.map((suggestion) => ({
              key: suggestion.value,
              text: stripHTML(suggestion.displayName),
              value: suggestion.value,
              content: <span dangerouslySetInnerHTML={{ __html: suggestion.displayName }} />,
            }));
            const filteredSuggestions = formattedSuggestions.filter(
              (suggestion) => !selectedValues.includes(suggestion.value) || suggestion.value === selectedOption
            );
            setSuggestions([noneOption, ...filteredSuggestions]);
          },
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormError(errors[0]?.detail ?? errors[0].title);
            }
          },
          onFinish: () => {
            setLoading(false);
          },
        })
      );
    },
    [dispatch, externalIntegrationId, organizationId, selectedValues, setFormError, workspaceId, values, selectedOption]
  );

  const throttledFetchSuggestions = useRef(
    throttle((value: string) => fetchSuggestions(value), 750, { leading: true, trailing: true })
  ).current;

  const handleInputChange = (newValue: string) => {
    setLoading(true);
    throttledFetchSuggestions(newValue);
  };

  const handleDropdownChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data;
    if (typeof value === 'string') {
      if (value === '') {
        onSelect(null, index);
      } else {
        onSelect(value, index);
      }
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    if (selectedOption && !suggestions.some((suggestion) => suggestion.value === selectedOption)) {
      setSuggestions([noneOption, ...suggestions.filter((suggestion) => suggestion.value !== noneOption.value)]);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <span className="custom-field-input">
      <Dropdown
        fluid
        search
        selection
        options={suggestions}
        onSearchChange={(_, { searchQuery }) => handleInputChange(searchQuery)}
        onChange={handleDropdownChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={isOpen}
        loading={loading}
        placeholder="Search for parent issues..."
        value={selectedOption ? selectedOption : ''}
        text={selectedOption ? selectedOption : 'Search for parent issues...'}
        selectOnNavigation={false}
        selectOnBlur={false}
        renderLabel={(item) => item.content}
      />
      {showRemoveButton &&
        renderDeleteIcon({
          onClick: () => onDelete(index),
          className: 'remove-custom-field',
        })}
    </span>
  );
};
