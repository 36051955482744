import { useEffect } from 'react';
import { Divider } from 'semantic-ui-react';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import LpLink from 'containers/shared/lp_link';
import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import { LoginAndRegistrationLogoBlock } from 'features/authentication/unauthenticated/login_and_registration_logo_block';
import { LpMotionFadeInAndUp } from 'features/common/animated_divs';
import { keySolid, LpIcon } from 'features/common/lp_icon';
import useHashParams from 'hooks/use_hash_params';
import useQueryParams from 'hooks/use_query_params';
import { SessionStorageKey, useSessionState } from 'hooks/use_session_state';
import { useUrlQueryParamSearch } from 'hooks/use_url_query_param_search';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

import { LoginSignUpAndReleaseNotes } from './login_sign_up_release_notes';
import { LpLoginForm } from './lp_login_form';

import './index.scss';

export const LoginPage = ({
  frontloadData,
}: {
  frontloadData: ({ organizations, providedOrgId, providedWsId }: FrontloadDataProps) => void;
}) => {
  const isPasswordResetLogin = useUrlQueryParamSearch('reset') === 'true';

  const { from } = useQueryParams();
  const hash = useHashParams();

  let redirectLocation = from;
  if (Object.keys(hash).length) {
    redirectLocation += '#';
    Object.keys(hash).forEach((h) => {
      redirectLocation += `${h}=${hash[h]}&`;
    });
    redirectLocation = redirectLocation?.replace(/&$/, '');
  }

  const [redirectLocationState, setRedirectLocationState] = useSessionState(
    redirectLocation,
    SessionStorageKey.RedirectLocation
  );

  useEffect(() => {
    setRedirectLocationState(redirectLocation);
  }, [redirectLocation, setRedirectLocationState]);

  return (
    <>
      {isPasswordResetLogin ? (
        <LoginAndRegistrationLogoBlock>
          <div className="login__password-recovery-header">
            <h3 className="login__password-recovery-top-header">Check Your Inbox</h3>
            <p className="login__password-recovery-sub-header">
              If the email matches a user in our system you should expect a recovery email to arrive in just a few
              minutes
            </p>
          </div>
          <LpLink to={frontend.login.url({})}>Return to Sign In</LpLink>
        </LoginAndRegistrationLogoBlock>
      ) : (
        <LpMotionFadeInAndUp className="login">
          <LoginSignUpAndReleaseNotes />

          <div className="login__form">
            <h3 className="login__title">
              Sign in to <ProductName />
            </h3>

            <LpLoginForm frontloadData={frontloadData} redirectLocation={redirectLocationState} />

            <Divider className="login__form-divider" horizontal>
              Or
            </Divider>

            <LpLink className="login__form-link-sso" to={frontend.loginSso.url({})}>
              <>
                <LpIcon className="login__form-link-sso-icon" icon={keySolid} />
                Sign in with Single Sign-On
              </>
            </LpLink>
          </div>
        </LpMotionFadeInAndUp>
      )}

      <LpLegalTermsFooter />
    </>
  );
};
