import { all, select, takeEvery } from 'typed-redux-saga';

import { MarketingConsentStatus } from 'daos/enums';
import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentUser, getCurrentUserId } from 'features/common/current/selectors';
import { setCurrentWorkspaceId } from 'features/common/current/slice';
import { getMarketingTags } from 'features/common/marketing_tags';
import { IdentifiedUserProperties, avoClient } from 'lib/avo/client';
import { idToSegmentReadableString } from 'lib/avo/helpers';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getCurrentOrganizationUser, getCurrentWorkspaceUser } from 'redux/entities/selectors/user';

const { segmentKey } = window as any;

function* handleUserIdentified() {
  const currentUserId = yield* select(getCurrentUserId);
  const currentUser = yield* select(getCurrentUser);
  const currentOrganizationUser = yield* select(getCurrentOrganizationUser);
  const currentWorkspaceUser = yield* select(getCurrentWorkspaceUser);
  const currentOrganizationId = yield* select(getCurrentOrganizationId);
  const organization = yield* select(getCurrentOrganization);
  const currentOrganizationPlan = yield* select(getCurrentOrganizationPlan);
  const marketingTags = yield* select(getMarketingTags);

  if (segmentKey && currentUserId) {
    const properties: IdentifiedUserProperties = {
      userId_: idToSegmentReadableString(currentUserId),
      emailUser: currentUser?.email ?? '',
      firstName: currentUser?.firstName ?? '',
      lastName: currentUser?.lastName ?? '',
      userTimezone: currentOrganizationUser?.timezone ?? '',
      userRole: currentOrganizationUser?.admin ? 'admin' : 'member',
      firstLogin: currentUser?.createdAt ?? '',
      accountIdUser: idToSegmentReadableString(currentOrganizationId),
      sfAccountId: currentOrganizationId,
      userCountry: currentUser?.countryCode ?? '',
      marketingConsent: `${currentUser?.marketingConsent == MarketingConsentStatus['Opt-In']}`,
      liquidplannerLicenseType: currentOrganizationPlan?.family ?? '',
      planId: idToSegmentReadableString(organization?.planId),
      liquidplannerCompanyName: organization?.name ?? '',
      guestUser: currentWorkspaceUser?.guestUser ? 'true' : 'false',
      lastLogin: currentWorkspaceUser?.lastAccessedAt ?? '',
      accountMemberNumber: idToSegmentReadableString(currentWorkspaceUser?.id),
      userUtmSource: marketingTags.utm_source ?? '',
      userUtmMedium: marketingTags.utm_medium ?? '',
      userUtmCampaign: marketingTags.utm_campaign ?? '',
      userUtmTerm: marketingTags.utm_term ?? '',
      userUtmContent: marketingTags.utm_content ?? '',
      phoneUser: currentUser?.phoneNumber ?? '',
      jobTitle: currentUser?.jobTitle ?? '',
      companySize: organization?.companySize ?? '',
    };

    avoClient?.identifiedUser(properties);

    fetch(
      backend.userAnalyticsIdentify.url({
        organizationId: currentOrganizationId,
        workspaceId: currentWorkspaceUser?.workspace?.id ?? 0,
      }),
      { method: 'POST' }
    );
  }
}

export default function* rootSaga() {
  yield* all([takeEvery(setCurrentWorkspaceId, handleUserIdentified)]);
}
