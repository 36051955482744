import { createSelector } from 'reselect';

import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { IterationFilter } from 'features/iterations/top_controls/types';
import { calcIterationsDateRange } from 'lib/date_range';
import { currentBrowserDateTime, currentBrowserTimezone } from 'lib/localization';
import { readonlyArray } from 'lib/readonly_record';
import { getItemsById, getItemForId } from 'redux/entities/selectors/item';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';
import { RootState } from 'redux/root_reducer';

export const getIterationsById = (state: RootState) => state.entities.iterations;
export const getIterationForId = (state: RootState, iterationId: number) => getIterationsById(state)[iterationId];

export const getIterationForItemId = createSelector(
  (state: RootState) => state,
  getItemForId,
  (state, item) => getIterationForId(state, item?.iterationId ?? 0)
);
const getIterationMetricsById = (state: RootState) => state.entities.iterationMetrics;
export const getIterationMetricsForId = (state: RootState, iterationId: number) =>
  getIterationMetricsById(state)[iterationId];

export const getIterationsForCurrentWorkspace = createSelector(
  getCurrentWorkspaceId,
  getCurrentOrganization,
  getIterationsById,
  (workspaceId, organization, iterationsById) => {
    if (!organization?.featureFlags.iterationBucket) {
      return [];
    }

    const workspaceIterations = Object.values(iterationsById)
      .filter((iteration) => iteration.workspaceId === workspaceId)
      .sort((a, b) => a.priority.localeCompare(b.priority));

    return readonlyArray(workspaceIterations);
  }
);

export const getIterationActiveAndOnHoldTaskCount = createSelector(getIterationMetricsForId, (iterationMetrics) => {
  const activeTaskCount = iterationMetrics?.activeTaskCount ?? 0;
  const onHoldTaskCount = iterationMetrics?.onHoldTaskCount ?? 0;

  return activeTaskCount + onHoldTaskCount;
});

export const getTasksForIterationId = createSelector(
  getItemsById,
  (_: RootState, iterationId: number) => iterationId,
  (itemsById, iterationId) => {
    return Object.values(itemsById).filter((item) => item.iterationId === iterationId);
  }
);

export const getItemIdsForIterationId = createSelector(
  getItemsById,
  (_: RootState, iterationId: number) => iterationId,
  (items, iterationId) =>
    Object.values(items)
      .filter((item) => item.iterationId === iterationId)
      .map((item) => item.id)
);

export const getFilteredIterationsForCurrentWorkspace = createSelector(
  getIterationsForCurrentWorkspace,
  (_: RootState, filter: IterationFilter) => filter,
  (iterations, filter) => {
    switch (filter) {
      case IterationFilter.Active:
        return iterations.filter((iteration) => !iteration.done);
      case IterationFilter.Done:
        return iterations.filter((iteration) => iteration.done);
      default:
        return iterations;
    }
  }
);

export const getIterationsDateRangeForFilter = createSelector(
  getFilteredIterationsForCurrentWorkspace,
  getIterationMetricsById,
  getCurrentOrganizationUser,
  (iterations, iterationMetricsById, currentOrgUser) => {
    const timezone = currentOrgUser?.timezone ?? currentBrowserTimezone();
    const localNow = currentBrowserDateTime(timezone);

    return calcIterationsDateRange({
      iterationMetricsById,
      iterations,
      localNow,
    });
  }
);
