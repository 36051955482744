// DO NOT EDIT - This file was autogenerated by backend/.../ColumnService.kt
export enum StandardColumns {
  Assigned = 'assigned',
  Assignment = 'assignment',
  AssignmentCountActive = 'assignmentCountActive',
  AssignmentCountDone = 'assignmentCountDone',
  AssignmentCountTotal = 'assignmentCountTotal',
  AssignmentDone = 'assignmentDone',
  AvailabilityUsed = 'availabilityUsed',
  BenchmarkEstimate = 'benchmarkEstimate',
  BillingRateSheet = 'billingRateSheet',
  ClippedEffort = 'clippedEffort',
  CostCode = 'costCode',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  CustomField = 'customField',
  DateRange = 'dateRange',
  DeletedAt = 'deletedAt',
  Description = 'description',
  DoneDate = 'doneDate',
  ExpectedFinish = 'expectedFinish',
  ExpectedStart = 'expectedStart',
  FinishRange = 'finishRange',
  FirstName = 'firstName',
  FolderStatus = 'folderStatus',
  Groups = 'groups',
  HighEstimate = 'highEstimate',
  ItemFileCount = 'itemFileCount',
  ItemId = 'itemId',
  IterationId = 'iterationId',
  IterationStatus = 'iterationStatus',
  LastName = 'lastName',
  LatestFinish = 'latestFinish',
  Location = 'location',
  Logged = 'logged',
  LoggedBillable = 'loggedBillable',
  LoggedNonBillable = 'loggedNonBillable',
  LowEstimate = 'lowEstimate',
  LpUserId = 'lpUserId',
  Name = 'name',
  OnHoldHours = 'onHoldHours',
  Package = 'package',
  PayRateSheet = 'payRateSheet',
  PercentComplete = 'percentComplete',
  PercentTasksComplete = 'percentTasksComplete',
  PriorityRush = 'priorityRush',
  Project = 'project',
  ProjectCountDone = 'projectCountDone',
  ProjectCountOnHold = 'projectCountOnHold',
  ProjectCountScheduled = 'projectCountScheduled',
  ProjectCountTotal = 'projectCountTotal',
  ProjectStatus = 'projectStatus',
  RemainingAvailability = 'remainingAvailability',
  RemainingWorkExpected = 'remainingWorkExpected',
  RemainingWorkHigh = 'remainingWorkHigh',
  RemainingWorkLow = 'remainingWorkLow',
  RemainingWorkRange = 'remainingWorkRange',
  ScheduleBar = 'scheduleBar',
  StoryPoint = 'storyPoint',
  StoryPointCountActive = 'storyPointCountActive',
  StoryPointCountDone = 'storyPointCountDone',
  StoryPointCountOnHold = 'storyPointCountOnHold',
  StoryPointSchemeName = 'storyPointSchemeName',
  SubFolder = 'subFolder',
  TargetFinish = 'targetFinish',
  TargetFinishDelta = 'targetFinishDelta',
  TargetFinishType = 'targetFinishType',
  TargetStart = 'targetStart',
  TargetStartDelta = 'targetStartDelta',
  Task = 'task',
  TaskCountActive = 'taskCountActive',
  TaskCountDone = 'taskCountDone',
  TaskCountOnHold = 'taskCountOnHold',
  TaskCountTotal = 'taskCountTotal',
  TaskStatus = 'taskStatus',
  TimesheetScheduled = 'timesheetScheduled',
  TimesheetScheduledBillableHours = 'timesheetScheduledBillableHours',
  TimesheetScheduledNonBillableHours = 'timesheetScheduledNonBillableHours',
  TotalAvailability = 'totalAvailability',
  TotalWorkBillable = 'totalWorkBillable',
  TotalWorkExpected = 'totalWorkExpected',
  TotalWorkHigh = 'totalWorkHigh',
  TotalWorkLow = 'totalWorkLow',
  TotalWorkNonBillable = 'totalWorkNonBillable',
  TotalWorkRange = 'totalWorkRange',
  TotalWorkScheduled = 'totalWorkScheduled',
  TrackingOnly = 'trackingOnly',
  UncertainWork = 'uncertainWork',
  UnloggedWork = 'unloggedWork',
  UnusedEffortExpected = 'unusedEffortExpected',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  UserStatus = 'userStatus',
  UserType = 'userType',
  Username = 'username',
  WorkLimit = 'workLimit',
  WorkLimitDelta = 'workLimitDelta',
  WorkType = 'workType',
  Workload = 'workload',
}
