import { SyncProjectJobErrorType } from 'daos/external_integration_enums';
import { PushTasksToJiraError } from 'daos/types';

export enum SyncErrorModalType {
  SyncError = 'syncError',
  PushTasksToJiraError = 'pushTasksToJiraError',
}

export interface GroupedPushTasksToJiraError {
  errorType: SyncProjectJobErrorType;
  errors: ReadonlyArray<PushTasksToJiraError>;
}

export enum SyncAndPushStatus {
  InProgress = 'inProgress',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}
