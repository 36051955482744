import { FormattedAssignees } from 'containers/shared/custom_column/assigned';
import {
  DateRangeColumnRenderer,
  ItemDateColumnRenderer,
  ItemMetricColumnRenderer,
  PeopleColumnRenderer,
  StoryPointsColumnRenderer,
} from 'containers/shared/custom_column/column_renderers';
import { CustomFieldColumnRenderer } from 'containers/shared/custom_column/custom_field';
import { ScheduleBarColumn } from 'containers/shared/custom_column/schedule_bar';
import { ScheduleDeltaRenderer } from 'containers/shared/custom_column/schedule_delta';
import { ColumnValueProps } from 'containers/shared/custom_column/types';
import { ScheduleDirectiveToScheduleDirectiveDisplayText, WorkTypeToWorkTypeDisplay } from 'daos/enums';
import { FolderStatusToFolderStatusDisplayMap, TargetFinishToTargetFinishDisplayMap } from 'daos/item_enums';
import { formatAsHoursWithNoRounding } from 'lib/display_helpers/format_as_hours';
import { DisplayValueWithOptionalWarningIcon } from 'lib/display_helpers/warning_icon_with_value';
import { secondsToHoursWithPrecision } from 'lib/helpers';

import { FrontendColumns, StandardColumns } from './enum';

const getColumnValue = (props: ColumnValueProps) => {
  const { selectedColumn, isApplicable = true, isSummaryRow = false } = props;

  if (!isApplicable) {
    return isSummaryRow ? '' : 'N/A';
  }

  switch (selectedColumn) {
    case StandardColumns.Assigned: {
      const { assigneeMap } = props;
      if (assigneeMap) {
        return <FormattedAssignees assigneeMap={assigneeMap} />;
      }

      return null;
    }

    case StandardColumns.Assignment:
      return props.assignmentName ?? '';

    case StandardColumns.AssignmentDone:
      return props.isDone ? 'Yes' : 'No';

    case StandardColumns.BenchmarkEstimate:
      return formatAsHoursWithNoRounding(secondsToHoursWithPrecision(props.benchmarkEstimate ?? 0));

    case StandardColumns.ClippedEffort:
      return formatAsHoursWithNoRounding(props.clippedEffortHours ?? 0);

    case StandardColumns.CostCode:
      return props.costCodeName ?? '';

    case StandardColumns.CreatedBy:
      return props.createdByUsername ?? '';

    case StandardColumns.CustomField: {
      if (props.customFieldColumnProps) {
        return <CustomFieldColumnRenderer {...props.customFieldColumnProps} />;
      }

      return null;
    }

    case StandardColumns.DateRange: {
      if (props.dateRange) {
        return <DateRangeColumnRenderer dateRange={props.dateRange} />;
      }
      return null;
    }

    case StandardColumns.Description:
      return props.description ?? '';

    case StandardColumns.FolderStatus:
    case StandardColumns.ProjectStatus:
      return props.folderStatus ? FolderStatusToFolderStatusDisplayMap[props.folderStatus] : '';

    case StandardColumns.ItemFileCount:
      return props.fileCount ?? 0;

    case StandardColumns.ItemId:
      return props.itemId ?? '';

    case StandardColumns.Location:
      return props.itemBreadcrumb ?? '';

    case FrontendColumns.None:
      return null;

    case StandardColumns.Package:
      return props.packageName ?? '';

    case StandardColumns.Project:
      return props.projectName ?? '';

    case StandardColumns.PriorityRush: {
      if (props.iterationName) {
        return props.iterationName;
      }

      return props.scheduleDirective ? ScheduleDirectiveToScheduleDirectiveDisplayText[props.scheduleDirective] : '';
    }

    case StandardColumns.BillingRateSheet:
      return props.billingRateSheetName ?? '';

    case StandardColumns.PayRateSheet:
      return props.payRateSheetName ?? '';

    case StandardColumns.ScheduleBar: {
      const { scheduleBarColumnProps } = props;
      if (scheduleBarColumnProps) {
        return <ScheduleBarColumn {...scheduleBarColumnProps} />;
      }

      return null;
    }

    case StandardColumns.TimesheetScheduledNonBillableHours:
      return props.timesheetScheduledNonBillableHours ?? 0;

    case StandardColumns.TimesheetScheduledBillableHours:
      return props.timesheetScheduledBillableHours ?? 0;

    case StandardColumns.TargetFinishDelta: {
      const { targetFinishDeltaColumn } = props;

      if (targetFinishDeltaColumn) {
        return <ScheduleDeltaRenderer {...targetFinishDeltaColumn} />;
      }
      return null;
    }

    case StandardColumns.TargetFinishType:
      return props.targetFinishType ? TargetFinishToTargetFinishDisplayMap[props.targetFinishType] : '';

    case StandardColumns.TargetStartDelta: {
      const { targetStartDeltaColumn } = props;

      if (targetStartDeltaColumn) {
        return <ScheduleDeltaRenderer {...targetStartDeltaColumn} />;
      }
      return null;
    }

    case StandardColumns.Task:
      return props.taskName ?? '';

    case StandardColumns.TaskStatus:
      return props.taskStatusName ?? '';

    case StandardColumns.TotalWorkBillable:
      return props.timesheetScheduledNonBillableHours ?? 0;

    case StandardColumns.TotalWorkNonBillable:
      return props.timesheetScheduledBillableHours ?? 0;

    case StandardColumns.TrackingOnly:
      return props.trackingOnly ? 'Yes' : 'No';

    case StandardColumns.SubFolder:
      return props.subFolders ?? '';

    case StandardColumns.UpdatedBy:
      return props.updatedByUsername ?? '';

    case StandardColumns.WorkLimit: {
      const { workLimitColumn } = props;

      if (workLimitColumn) {
        return (
          <DisplayValueWithOptionalWarningIcon
            value={formatAsHoursWithNoRounding(workLimitColumn.workLimitHours)}
            showWarningIcon={workLimitColumn.workLimitAlert}
          />
        );
      }
      return null;
    }

    case StandardColumns.WorkType:
      return props.workType ? WorkTypeToWorkTypeDisplay[props.workType] : '';

    case StandardColumns.StoryPoint:
    case StandardColumns.StoryPointCountActive:
    case StandardColumns.StoryPointCountOnHold:
    case StandardColumns.StoryPointCountDone:
      return <StoryPointsColumnRenderer column={selectedColumn} itemMetricProps={props} />;

    case StandardColumns.CreatedAt:
    case StandardColumns.DoneDate:
    case StandardColumns.ExpectedFinish:
    case StandardColumns.ExpectedStart:
    case StandardColumns.FinishRange:
    case StandardColumns.LatestFinish:
    case StandardColumns.TargetFinish:
    case StandardColumns.TargetStart:
    case StandardColumns.UpdatedAt: {
      return <ItemDateColumnRenderer itemDateProps={props} column={selectedColumn} />;
    }

    case StandardColumns.AssignmentCountTotal:
    case StandardColumns.AssignmentCountActive:
    case StandardColumns.AssignmentCountDone:
    case FrontendColumns.EarliestDoneDate:
    case FrontendColumns.EffectiveTargetFinish:
    case FrontendColumns.EffectiveTargetStart:
    case FrontendColumns.LatestDoneDate:
    case StandardColumns.Logged:
    case StandardColumns.LoggedBillable:
    case StandardColumns.LoggedNonBillable:
    case StandardColumns.OnHoldHours:
    case StandardColumns.ProjectCountScheduled:
    case StandardColumns.ProjectCountDone:
    case StandardColumns.ProjectCountOnHold:
    case StandardColumns.ProjectCountTotal:
    case StandardColumns.PercentComplete:
    case FrontendColumns.PercentCompleteNumericValueAsString:
    case StandardColumns.PercentTasksComplete:
    case StandardColumns.RemainingWorkExpected:
    case StandardColumns.RemainingWorkRange:
    case StandardColumns.RemainingWorkLow:
    case StandardColumns.RemainingWorkHigh:
    case FrontendColumns.RollupEarliestActiveTargetFinish:
    case FrontendColumns.RollupEarliestTargetStart:
    case FrontendColumns.RollupLatestTargetFinish:
    case StandardColumns.TaskCountActive:
    case StandardColumns.TaskCountDone:
    case StandardColumns.TaskCountOnHold:
    case StandardColumns.TaskCountTotal:
    case StandardColumns.TimesheetScheduled:
    case StandardColumns.TotalWorkExpected:
    case StandardColumns.TotalWorkRange:
    case StandardColumns.TotalWorkLow:
    case StandardColumns.TotalWorkHigh:
    case StandardColumns.TotalWorkScheduled:
    case StandardColumns.UncertainWork:
    case StandardColumns.UnusedEffortExpected:
    case StandardColumns.WorkLimitDelta: {
      return <ItemMetricColumnRenderer column={selectedColumn} itemMetricProps={props} />;
    }

    case StandardColumns.RemainingAvailability:
    case StandardColumns.AvailabilityUsed:
    case StandardColumns.FirstName:
    case StandardColumns.Groups:
    case StandardColumns.LastName:
    case StandardColumns.LpUserId:
    case StandardColumns.TotalAvailability:
    case StandardColumns.UnloggedWork:
    case StandardColumns.Username:
    case StandardColumns.UserStatus:
    case StandardColumns.UserType:
    case StandardColumns.Workload: {
      return <PeopleColumnRenderer column={selectedColumn} peopleProps={props} />;
    }

    case StandardColumns.IterationId:
      return props.iterationId;

    case StandardColumns.IterationStatus:
      return props.iterationStatus;

    default:
      return null;
  }
};

export const ColumnValueRenderer = (props: ColumnValueProps) => {
  const columnValue = getColumnValue(props);

  return <>{columnValue}</>;
};
