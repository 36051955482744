import { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import './story_point_dropdown.scss';

interface StoryPointDropdownProps {
  onChange: (storyPointId: number | null) => void;
  open?: boolean;
  options: DropdownProps['options'];
  selectedStoryPointId: number;
  usePortal?: boolean;
}

export const StoryPointDropdown = ({
  onChange,
  open,
  options,
  selectedStoryPointId,
  usePortal = false,
}: StoryPointDropdownProps) => {
  const handleChange = (_event: SyntheticEvent, { value }: DropdownProps) => {
    if (!value) {
      return onChange(null);
    }

    return onChange(value as number);
  };

  const dropdown = (
    <Dropdown
      className="story-point-dropdown"
      onChange={handleChange}
      open={open}
      options={options}
      value={selectedStoryPointId ?? 0}
      selection
      selectOnNavigation={false}
    />
  );

  return usePortal ? <PortalDropdown>{dropdown}</PortalDropdown> : dropdown;
};
