import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WorkspaceDao } from 'daos/workspace';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { awaitRequestFinish } from 'lib/api';

export const useWorkspaceFetchOnOrganizationModified = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const fetchWorkspace = useCallback(() => {
    const { uuid } = dispatch(
      WorkspaceDao.fetch({ organizationId, workspaceId }, { include: { includeSystemSettings: true } })
    );
    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch, organizationId, workspaceId]);

  useEvents({
    event: Events.ORGANIZATION_MODIFIED,
    callback: fetchWorkspace,
    scope: EventScopes.None,
  });
};
