import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SourceSystem } from 'daos/external_integration_enums';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { avoClient } from 'lib/avo/client';
import { ProductName } from 'lib/use_product_name';

const svgPixelSize = 584;
const { segmentKey } = window as any;

const WelcomeSection = () => {
  const { packageId } = useJiraProjectModalContext();
  const workspaceId = useSelector(getCurrentWorkspaceId);

  useEffect(() => {
    if (segmentKey) {
      avoClient?.integrationStarted({
        integrationName: SourceSystem.JIRA,
        packageId: packageId.toString(),
        workspaceId: workspaceId.toString(),
      });
    }
  }, [packageId, workspaceId]);

  return (
    <div className="jira-project-modal__content__welcome">
      <LpSvg icon={LpSvgs.JIRA_SOFTWARE} size={svgPixelSize} />
      <div className="jira-project-modal__content__welcome-text" data-testid="jiraWelcomeText">
        You are just a few steps away from integrating <ProductName /> with Jira.
      </div>
    </div>
  );
};

export default WelcomeSection;
