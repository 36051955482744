import { Assignment } from 'daos/assignment';
import { ItemType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { RelativePriorityType } from 'daos/item_enums';
import { Item, CustomField } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { TaskStatusDao } from 'daos/task_status';
import { createCustomFieldValuesPayload } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { AddEditGridRows } from 'features/common/data_grid/types';
import { SECONDS_IN_HOUR } from 'lib/constants';
import { convertEstimateInputsToNumbers } from 'lib/conversion_helpers/estimate_conversions';
import { convertHoursToSeconds } from 'lib/helpers';
import { EntityLookupById } from 'redux/entities/types';

const firstAssignmentPayload = ({
  orgUserId,
  lowEffortHours,
  highEffortHours,
}: {
  orgUserId?: number;
  lowEffortHours?: string;
  highEffortHours?: string;
}): Array<Partial<Assignment>> | undefined => {
  const isEmptyAssignment = !orgUserId && !lowEffortHours && !highEffortHours;

  if (isEmptyAssignment) {
    return;
  }

  const { lowNumber, highNumber } = convertEstimateInputsToNumbers({
    low: lowEffortHours ?? '0',
    high: highEffortHours ?? '0',
  });

  return [
    {
      itemType: ItemType.ASSIGNMENTS,
      highEffort: highNumber * SECONDS_IN_HOUR,
      lowEffort: lowNumber * SECONDS_IN_HOUR,
      organizationUser: orgUserId ? OrganizationUserDao.id(orgUserId) : undefined,
    },
  ];
};

export const getWorkLimitPayload = (rowWorkLimit: string | number | undefined) => {
  return convertHoursToSeconds(Number(rowWorkLimit)) || null;
};

export const getBenchmarkEstimatePayload = (rowBenchmarkEstimate: number | undefined) => {
  return convertHoursToSeconds(Number(rowBenchmarkEstimate)) || null;
};
export const getTaskAndAssignmentBulkCreatePayload = (
  rows: AddEditGridRows,
  parentItemId: number,
  relativePriorityType: RelativePriorityType,
  customFieldsById: EntityLookupById<CustomField>
) => {
  const payload: Array<Partial<Item>> = rows.map((row) => {
    const taskStatus = row.taskStatusId ? TaskStatusDao.id(row.taskStatusId) : undefined;
    const workLimit = getWorkLimitPayload(row.workLimit);
    const fieldValues = createCustomFieldValuesPayload({
      row,
      customFieldsKeyedById: customFieldsById,
      initialRowFieldValues: [],
    });
    return {
      description: row.description,
      fieldValues,
      itemType: ItemType.TASKS,
      name: row.name,
      parent: ItemDao.id(parentItemId),
      relativePriority: { type: relativePriorityType },
      scheduleDirective: row.scheduleDirective,
      iterationId: row.iterationId ?? null,
      targetFinish: row.targetFinish,
      targetFinishType: row.targetFinishType,
      targetStart: row.targetStart,
      taskStatus,
      workLimit,
      workType: row.workType,
      children: firstAssignmentPayload({
        orgUserId: row.organizationUserId,
        lowEffortHours: String(row.lowEffortHours),
        highEffortHours: String(row.highEffortHours),
      }),
    };
  });

  return relativePriorityType === RelativePriorityType.AFTER ? payload : payload.reverse();
};
