import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'jiraSuggestions',
  type: 'JIRA_SUGGESTION',
});

const { JIRA_SUGGESTION } = resource;

interface JiraSuggestionsParams {
  organizationId: number;
  workspaceId: number;
}

export enum JiraAutoCompleteField {
  Parent = 'PARENT',
}

interface JiraSuggestionsQuery {
  field: JiraAutoCompleteField;
  fieldValue: string;
  cloudId?: string;
  oauthCredentialsId?: number;
  externalIntegrationId?: number;
}

const fetchJiraSuggestions = (params: JiraSuggestionsParams, query: JiraSuggestionsQuery) => {
  return request(backend.jiraSuggestions.url(params, { query }), JIRA_SUGGESTION, {
    method: HttpMethod.GET,
  });
};

export const JiraSuggestionsDao = {
  fetchJiraSuggestions,
} as const;
