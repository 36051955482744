import {
  useGetScheduleBarRendererProps,
  useIterationScheduleBarProps,
} from 'features/common/schedule_bar/use_get_schedule_bar_renderer_props';
import { ScheduleViewType } from 'hooks/use_items_date_range';
import { DateRange } from 'redux/entities/selectors/item';

import { ScheduleBarRenderer } from './renderer';

import './index.scss';

interface ScheduleBarProps {
  dateRange: DateRange;
}

interface ItemScheduleBarProps extends ScheduleBarProps {
  hasAssignmentPredecessor?: boolean;
  hasAssignmentSuccessor?: boolean;
  isAssignmentPlaceholder?: boolean;
  isAssignmentTrackingOnly?: boolean;
  isDateTimeMode?: boolean;
  isDebugMode?: boolean;
  itemId: number;
  showDateLabels: boolean;
  viewType: ScheduleViewType;
}

interface IterationScheduleBarProps extends ScheduleBarProps {
  iterationId: number;
}

export const ScheduleBar = ({
  dateRange,
  isAssignmentPlaceholder = false,
  isAssignmentTrackingOnly = false,
  isDateTimeMode = false,
  isDebugMode = false,
  itemId,
  showDateLabels,
  viewType,
  hasAssignmentPredecessor = false,
  hasAssignmentSuccessor = false,
}: ItemScheduleBarProps) => {
  const scheduleBarRendererProps = useGetScheduleBarRendererProps({
    dateRange,
    hasAssignmentPredecessor,
    hasAssignmentSuccessor,
    isAssignmentPlaceholder,
    isAssignmentTrackingOnly,
    isDateTimeMode,
    isDebugMode,
    itemId,
    showDateLabels,
    viewType,
  });

  if (!scheduleBarRendererProps) {
    return null;
  }

  return <ScheduleBarRenderer {...scheduleBarRendererProps} />;
};

export const IterationScheduleBar = (props: IterationScheduleBarProps) => {
  const scheduleBarRendererProps = useIterationScheduleBarProps(props);

  if (scheduleBarRendererProps) {
    return <ScheduleBarRenderer {...scheduleBarRendererProps} />;
  }

  return null;
};
