import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Image, Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import LpLink from 'containers/shared/lp_link';
import { TermsOfServiceLabel } from 'containers/shared/terms_of_service_label';
import { MarketingConsentStatus, PlanFamily } from 'daos/enums';
import { OrganizationUser } from 'daos/model_types';
import { RegistrationDao } from 'daos/registration';
import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import { registrationErrors } from 'features/authentication/unauthenticated/registration/errors';
import { LpMotionFadeInAndUp } from 'features/common/animated_divs';
import { setCurrentUserId } from 'features/common/current/slice';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormCheckbox from 'features/common/forms/lp_form_checkbox';
import { LpFormDropdown } from 'features/common/forms/lp_form_dropdown';
import LpFormInput from 'features/common/forms/lp_form_input';
import { getMarketingTags } from 'features/common/marketing_tags';
import { awaitRequestFinish } from 'lib/api';
import { OriginPage, avoClient } from 'lib/avo/client';
import { LIQUIDPLANNER_URL, MIN_PASSWORD_LENGTH } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';
import {
  AnalyticsRegistrationEvent,
  logGoogleAnalyticsRegistrationEvent,
} from 'lib/google_analytics/log_google_analytics_event';
import { useYupEmailValidator } from 'lib/helpers/yup/lp_email_validation';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { currentBrowserLocale, currentBrowserTimezone } from 'lib/localization';
import { defaultLandingPage, frontend } from 'lib/urls';
import liquidPlannerLogo from 'static/img/portfolio-manager-tempo-logo.png';

import 'features/authentication/unauthenticated/registration/index.scss';

const requireSignupKey = window.relightRequireSignupKey === 'true';

const SIGNUP_KEY_LABEL = 'Signup Key';

const phoneNumberFormattingCharacters = /[-_()., +]/g;
const phoneNumberValidPattern = /^\d{10,14}$/;

const { segmentKey } = window as any;

enum CompanySize {
  from0To49 = 'from0To49',
  from50To99 = 'from50To99',
  from100To249 = 'from100To249',
  from250To499 = 'from250To499',
  from500To1999 = 'from500To1999',
  from2000To4999 = 'from2000To4999',
  from5000 = 'from5000',
}

const companySizes = [
  CompanySize.from0To49,
  CompanySize.from50To99,
  CompanySize.from100To249,
  CompanySize.from250To499,
  CompanySize.from500To1999,
  CompanySize.from2000To4999,
  CompanySize.from5000,
];

const companySizeDisplayValue = {
  [CompanySize.from0To49]: '0-49',
  [CompanySize.from50To99]: '50-99',
  [CompanySize.from100To249]: '100-249',
  [CompanySize.from250To499]: '250-499',
  [CompanySize.from500To1999]: '500-1,999',
  [CompanySize.from2000To4999]: '2,000-4,999',
  [CompanySize.from5000]: '5,000+',
};

enum JobTitle {
  businessOwner = 'businessOwner',
  executive = 'executive',
  teamManager = 'teamManager',
  individualContributor = 'individualContributor',
  consultantPartner = 'consultantPartner',
  student = 'student',
  unknown = 'unknown',
}

const jobTitles = [
  JobTitle.businessOwner,
  JobTitle.executive,
  JobTitle.teamManager,
  JobTitle.individualContributor,
  JobTitle.consultantPartner,
  JobTitle.student,
  JobTitle.unknown,
];

const jobTitleDisplayValue = {
  [JobTitle.businessOwner]: 'Business Owner',
  [JobTitle.executive]: 'Executive',
  [JobTitle.teamManager]: 'Team Manager',
  [JobTitle.individualContributor]: 'Individual Contributor',
  [JobTitle.consultantPartner]: 'Consultant/Partner',
  [JobTitle.student]: 'Student',
  [JobTitle.unknown]: 'Rather Not Say',
};

const companySizeOptions = companySizes.map((companySize) => ({
  key: companySize.toString(),
  search: companySizeDisplayValue[companySize],
  text: companySizeDisplayValue[companySize],
  value: companySize.toString(),
}));

const jobTitleOptions = jobTitles.map((jobTitle) => ({
  key: jobTitle.toString(),
  search: jobTitleDisplayValue[jobTitle],
  text: jobTitleDisplayValue[jobTitle],
  value: jobTitle.toString(),
}));

export const Registration = ({
  frontloadData,
}: {
  frontloadData: ({ organizations, providedOrgId, providedWsId }: FrontloadDataProps) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [avoSignUpStarted, setAvoSignUpStarted] = useState(false);

  const { location } = history;
  const marketingTags = getMarketingTags();

  const registrationValidationSchema = yup
    .object()
    .shape({
      email: useYupEmailValidator(),
      firstName: yup.string().trim().required(lpErrorText.firstName),
      lastName: yup.string().trim().required(lpErrorText.lastName),
      password: yup.string().min(MIN_PASSWORD_LENGTH, lpErrorText.passwordLength).required(lpErrorText.passwordLength),
      passwordConfirmation: yup.string().required(lpErrorText.passwordConfirmation),
      phoneNumber: yup
        .string()
        .trim()
        .transform((value) => value.replace(phoneNumberFormattingCharacters, ''))
        .matches(phoneNumberValidPattern, lpErrorText.phoneNumber),
      companyName: yup.string().trim().required(lpErrorText.companyName),
      requireSignupKey: yup.boolean(),
      signupKey: yup
        .string()
        .label(SIGNUP_KEY_LABEL)
        .when('requireSignupKey', {
          is: true,
          then: (schema) => schema.required(lpErrorText.signUpKey),
          otherwise: (schema) => schema.notRequired(),
        }),
      tosCheckbox: yup
        .boolean()
        .required()
        .default(false)
        .test('tos checkbox is false', lpErrorText.termsOfService, (tosCheckBox) => tosCheckBox === true),
      jobTitle: yup.string().trim().required(lpErrorText.jobTitle),
      companySize: yup.string().trim().required(lpErrorText.companySize),
    })
    .test('passwords-do-not-match', function ({ password, passwordConfirmation }) {
      if (password !== passwordConfirmation) {
        return this.createError({
          message: lpErrorText.passwordMatch,
          path: 'passwordConfirmation',
        });
      }

      return true;
    });

  useEffect(() => {
    logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationBegan);

    try {
      localStorage.clear();
      // eslint-disable-next-line no-empty
    } catch (_) {
      // empty catch to handle users who block localStorage use on their browsers
    }
  }, []);

  const {
    handleSubmit,
    setStatus: setFormApiError,
    status: formApiError,
    isSubmitting,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    values,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      passwordConfirmation: '',
      phoneNumber: '',
      companyName: '',
      requireSignupKey,
      signupKey: '',
      tosCheckbox: false,
      jobTitle: '',
      companySize: '',
    },
    validationSchema: registrationValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      companyName,
      signupKey,
      tosCheckbox,
      jobTitle,
      companySize,
    }) => {
      setFormApiError(undefined);

      const { uuid } = dispatch(
        RegistrationDao.registerAccount({
          email,
          firstName,
          lastName,
          password,
          phoneNumber,
          organizationName: companyName,
          companySize,
          jobTitle,
          signupKey,
          organizationProjectTypes: [],
          planFamily: PlanFamily.UltimateTrial,
          creator: {
            locale: currentBrowserLocale(),
            timezone: currentBrowserTimezone(),
          },
          marketingConsent: MarketingConsentStatus['Opt-In'],
          tosAccepted: tosCheckbox,
        })
      );

      dispatch(
        awaitRequestFinish<OrganizationUser>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormApiError(errors[0]);
            }

            setSubmitting(false);
            logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationFailed);
          },
          onSuccess: ({ data }) => {
            dispatch(setCurrentUserId(data.user.id));

            frontloadData({
              providedOrgId: data.organization.id,
              providedWsId: undefined,
              callbackOnFrontloadDataComplete: (workspaceId) => {
                history.push(defaultLandingPage.url({ organizationId: data.organization.id, workspaceId }));
              },
            });
            if (segmentKey) {
              avoClient?.signUpCompleted({
                originPage: OriginPage.TRIAL_SIGN_UP,
                url: location.pathname,
                userFirstName: firstName,
                userLastName: lastName,
                lpCompanyName: companyName,
                utmSource: marketingTags.utm_source ?? '',
                utmMedium: marketingTags.utm_medium ?? '',
                utmCampaign: marketingTags.utm_campaign ?? '',
                utmTerm: marketingTags.utm_term ?? '',
                utmContent: marketingTags.utm_content ?? '',
                email: email ?? '',
              });
            }
            logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationCompletedUserData);
            logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationCompleted);
          },
        })
      );
    },
  });

  useEffect(() => {
    if (!avoSignUpStarted && Object.values(values).some((value) => !!value)) {
      if (segmentKey) {
        avoClient?.signUpStarted({
          originPage: OriginPage.TRIAL_SIGN_UP,
          url: location.pathname,
        });
      }
      setAvoSignUpStarted(true);
    }
  }, [values, avoSignUpStarted, location.pathname]);

  const handleDismissApiError = () => setFormApiError(undefined);

  return (
    <>
      <LpMotionFadeInAndUp className="lp-registration">
        <NewWindowLink to={LIQUIDPLANNER_URL}>
          <Image centered className="lp-registration__logo" draggable={false} src={liquidPlannerLogo} />
        </NewWindowLink>
        <p className="lp-registration__label">Create an account for free</p>
        <Form className="lp-registration__form" autoComplete="on" onSubmit={handleSubmit} loading={isSubmitting}>
          {formApiError && (
            <LpErrorMessage
              className="lp-registration__api-error"
              error={formApiError}
              customError={registrationErrors(formApiError.code, formApiError.detail)}
              onDismiss={handleDismissApiError}
            />
          )}
          <LpFormInput
            e2eTestId="businessEmail"
            disableLastPass={false}
            fieldKey="email"
            className="lp-registration__input-field"
            label="Business Email"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
          />
          <div className="lp-registration__column">
            <LpFormInput
              e2eTestId="firstName"
              fieldKey="firstName"
              className="lp-registration__input-field"
              label="First Name"
              getFieldProps={getFieldProps}
              getFieldMeta={getFieldMeta}
            />

            <LpFormInput
              e2eTestId="lastName"
              fieldKey="lastName"
              className="lp-registration__input-field"
              label="Last Name"
              getFieldProps={getFieldProps}
              getFieldMeta={getFieldMeta}
            />
          </div>

          <LpFormInput
            e2eTestId="signupPassword"
            disableLastPass={false}
            fieldKey="password"
            className="lp-registration__input-field"
            label="Password"
            type="password"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
          />

          <LpFormInput
            e2eTestId="passwordConfirmation"
            disableLastPass={false}
            fieldKey="passwordConfirmation"
            className="lp-registration__input-field"
            label="Confirm Password"
            type="password"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
          />

          <LpFormDropdown
            e2eTestId="jobTitle"
            fieldKey="jobTitle"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            label="Job Title"
            setFieldValue={setFieldValue}
            options={jobTitleOptions}
          />

          <LpFormInput
            e2eTestId="phoneNumber"
            fieldKey="phoneNumber"
            className="lp-registration__input-field"
            label="Business Phone Number"
            type="tel"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
          />

          <div className="lp-registration__column">
            <LpFormInput
              e2eTestId="companyName"
              fieldKey="companyName"
              className="lp-registration__input-field"
              getFieldProps={getFieldProps}
              getFieldMeta={getFieldMeta}
              label="Company Name"
            />

            <LpFormDropdown
              e2eTestId="companySize"
              fieldKey="companySize"
              label="Company Size"
              getFieldMeta={getFieldMeta}
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              options={companySizeOptions}
            />
          </div>

          {requireSignupKey && (
            <LpFormInput
              fieldKey="signupKey"
              className="lp-registration__input-field"
              label={SIGNUP_KEY_LABEL}
              getFieldProps={getFieldProps}
              getFieldMeta={getFieldMeta}
            />
          )}

          <LpFormCheckbox
            e2eTestId="tosCheckbox"
            fieldKey="tosCheckbox"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            label={<TermsOfServiceLabel />}
          />

          <div className="lp-registration__actions">
            <Button
              data-e2e-test-id="createAccount"
              className="lp-registration__submit-button"
              content="Create Account"
              type="submit"
              disabled={isSubmitting}
            />
          </div>
          <div className="lp-registration__sign-in">
            <p>
              Already have a login? <LpLink to={frontend.login.url({})}>Sign in</LpLink>
            </p>
          </div>
        </Form>
      </LpMotionFadeInAndUp>
      <LpLegalTermsFooter />
    </>
  );
};
