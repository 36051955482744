import { ReactNode } from 'react';

import { LpIcon, IconDefinition } from 'features/common/lp_icon';

import { PremiumFeaturesTrialButtons } from './buttons_actions';

import './feature_trial_banner_with_icon.scss';

const FeatureTrialBannerWithIcon = ({
  className = '',
  description,
  icon,
  seePlansButtonText = 'See Plans',
  showTrialButton = true,
  title,
}: {
  className?: string;
  description: ReactNode;
  icon: IconDefinition;
  seePlansButtonText?: string;
  showTrialButton?: boolean;
  title: string;
}) => {
  return (
    <div className={`feature-trial-banner-with-icon ${className}`}>
      <div className="feature-trial-banner-with-icon__icon">
        <LpIcon size="lg" icon={icon} />
      </div>
      <div className="feature-trial-banner-with-icon__title">{title}</div>
      <div className="feature-trial-banner-with-icon__description">{description}</div>
      <div className="feature-trial-banner-with-icon__actions-text">Interested in?</div>
      <div className="feature-trial-banner-with-icon__actions-buttons">
        <PremiumFeaturesTrialButtons showTrialButton={showTrialButton} seePlansButtonText={seePlansButtonText} />
      </div>
    </div>
  );
};

export default FeatureTrialBannerWithIcon;
