import { useFormikContext } from 'formik';
import { useState } from 'react';
import { List, Table } from 'semantic-ui-react';

import ClickableText from 'features/common/clickable_text';
import { LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  getSubFolderOrganizationOptions,
  isOptionDisabled,
} from 'features/jira_project/modal/sections/sync_settings_section/helpers';
import { HierarchyLevelRadioButton } from 'features/jira_project/modal/sections/sync_settings_section/hierarchy_level_radio_button';
import { SubfolderExampleModal } from 'features/jira_project/modal/sections/sync_settings_section/subfolder_example_modal';
import { JiraProjectModalFormValues, SubFolderOrganizationValues } from 'features/jira_project/modal/types';
import { gray600 } from 'style/variables';

import { DisabledRadioButton } from './disabled_radio_button';

import 'features/jira_project/modal/jira_project_modal.scss';

export const SubFolderOrganizationRow = () => {
  const [exampleSelected, setExampleSelected] = useState<SubFolderOrganizationValues | undefined>(undefined);

  const { isPremiumJiraUser, canSelectLowerHierarchicalLevel } = useJiraProjectModalContext();
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const { taskGroupHierarchy } = values;
  const subFolderOrganizationOptions = getSubFolderOrganizationOptions(isPremiumJiraUser);

  const helpText = (
    <div className="jira-project-modal__content-sync-settings-subfolder-organization-help-text">
      <div>Tasks are displayed as a flat list if no Epics or custom hierarchy levels exist.</div>{' '}
      <div>
        <b>Please note:</b> Once a Sub-Folder structure is set up, it cannot be reverted to a flat list. However, you
        can always change a flat list to a Sub-Folder setup at any time.
      </div>
    </div>
  );

  const subFolderOrganizationTitle = (
    <>
      Sub-folder Organization
      <LpPopUp placement="top" content={helpText} trigger={<LpIcon color={gray600} icon={questionCircleSolid} />} />
    </>
  );

  const handleViewExample = (option: SubFolderOrganizationValues) => {
    setExampleSelected(option);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={subFolderOrganizationTitle} />
      <Table.Cell>
        <List>
          <List.Item>
            <List.List className="jira-project-modal__content-sync-settings-subfolder-organization">
              {subFolderOrganizationOptions.map((option) => (
                <List.Item
                  key={option.id}
                  className="jira-project-modal__content-sync-settings-subfolder-organization-option"
                >
                  {isOptionDisabled(option.id, canSelectLowerHierarchicalLevel) ? (
                    <DisabledRadioButton option={option}>
                      <HierarchyLevelRadioButton
                        taskGroupHierarchy={taskGroupHierarchy}
                        option={option}
                        setFieldValue={setFieldValue}
                        canSelectLowerHierarchicalLevel={canSelectLowerHierarchicalLevel}
                      />
                    </DisabledRadioButton>
                  ) : (
                    <HierarchyLevelRadioButton
                      taskGroupHierarchy={taskGroupHierarchy}
                      option={option}
                      setFieldValue={setFieldValue}
                      canSelectLowerHierarchicalLevel={canSelectLowerHierarchicalLevel}
                    />
                  )}
                  <div>
                    <b>{option.name}</b>:
                    <span className="jira-project-modal__content-sync-settings-subfolder-organization-description-text">
                      {option.description}
                    </span>
                    <ClickableText text="See Example" onClick={() => handleViewExample(option)} />
                  </div>
                  {exampleSelected && (
                    <SubfolderExampleModal setExampleSelected={setExampleSelected} exampleSelected={exampleSelected} />
                  )}
                </List.Item>
              ))}
            </List.List>
          </List.Item>
        </List>
      </Table.Cell>
      <Table.Cell />
    </JiraProjectModalTableRow>
  );
};
