import { ItemMetrics } from 'daos/model_types';

interface TotalWorkProps {
  loggedWork: number;
  lowRemainingWork: number;
  highRemainingWork: number;
}

function getAverage(low: number, high: number) {
  return (low + high) / 2;
}

function _getUncertainWork(highRemainingWork: number, lowRemainingWork: number) {
  return Math.max(0, highRemainingWork - lowRemainingWork);
}

function _getRemainingWorkExpected(lowRemainingWork: number, highRemainingWork: number) {
  return getAverage(lowRemainingWork, highRemainingWork);
}

function _getTotalWorkExpected({ loggedWork, lowRemainingWork, highRemainingWork }: TotalWorkProps) {
  const remainingWorkExpected = _getRemainingWorkExpected(lowRemainingWork, highRemainingWork);
  return remainingWorkExpected + loggedWork;
}

export const getUncertainWork = (itemMetrics?: ItemMetrics) => {
  return itemMetrics ? _getUncertainWork(itemMetrics.highRemainingWork, itemMetrics.lowRemainingWork) : 0;
};

export const getRemainingWorkExpected = (itemMetrics?: ItemMetrics) => {
  return itemMetrics ? _getRemainingWorkExpected(itemMetrics.lowRemainingWork, itemMetrics.highRemainingWork) : 0;
};

export const getOnHoldHours = (itemMetrics?: ItemMetrics) => {
  return itemMetrics ? getAverage(itemMetrics.lowOnHoldWork, itemMetrics.highOnHoldWork) : 0;
};

export const getTotalWorkExpected = (itemMetrics?: ItemMetrics) => {
  if (!itemMetrics) {
    return 0;
  }

  return _getTotalWorkExpected({
    loggedWork: itemMetrics.loggedWork,
    lowRemainingWork: itemMetrics.lowRemainingWork,
    highRemainingWork: itemMetrics.highRemainingWork,
  });
};

export const getPercentComplete = (amount: number, total: number) =>
  !amount || !total ? 0 : Math.round((amount / total) * 100);

export const getStoryPointItemMetricDisplay = ({
  totalStoryPointMetric,
  storyPointMetric,
}: {
  totalStoryPointMetric: number | null | undefined;
  storyPointMetric: number | null | undefined;
}) => {
  const hasAssignedStoryPoints = totalStoryPointMetric !== null;
  const storyPointValue = storyPointMetric ?? '';

  return hasAssignedStoryPoints ? storyPointValue : 'None';
};

export const getIterationRemainingWorkExpected = (lowRemainingWork: number, highRemainingWork: number) => {
  return _getRemainingWorkExpected(lowRemainingWork, highRemainingWork);
};

export const getIterationTotalWorkExpected = (props: TotalWorkProps) => {
  return _getTotalWorkExpected(props);
};

export const getIterationUncertainWork = (highRemainingWork: number, lowRemainingWork: number) => {
  return _getUncertainWork(highRemainingWork, lowRemainingWork);
};
