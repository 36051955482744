import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationDao } from 'daos/organization';
import { OrganizationLinkCrumb } from 'features/common/breadcrumbs/breadcrumb_helpers';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import UpgradeFeatureTrialBanner from 'features/common/feature_trial/upgrade_page_trial_banner';
import {
  shieldCheckSolid,
  peopleCarrySolid,
  checkSolid,
  filterSolid,
  usersSolid,
  keySolid,
  LpIcon,
  tagsSolid,
  userClockSolid,
  giftSolid,
  slack,
  coinSolid,
  IconDefinition,
  fileSolid,
} from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { awaitRequestFinish } from 'lib/api';
import { FILE_STORAGE_DESCRIPTION, LP_PRICING_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';
import { ProductName } from 'lib/use_product_name';
import './index.scss';

interface UpgradeFeatureItem {
  icon: IconDefinition;
  title: string;
  description: ReactNode;
}

interface UpgradeFeatureList {
  AccessRoles: UpgradeFeatureItem;
  CustomDataFields: UpgradeFeatureItem;
  CustomFiltering: UpgradeFeatureItem;
  FileStorage: UpgradeFeatureItem;
  CustomizableCostCodes: UpgradeFeatureItem;
  GroupWorkloadAndBoardViews: UpgradeFeatureItem;
  MemberAndResourceGroups: UpgradeFeatureItem;
  ProjectAccessControls: UpgradeFeatureItem;
  SlackIntegration: UpgradeFeatureItem;
  TimesheetReviewAndExport: UpgradeFeatureItem;
}

const upgradeFeaturesList: UpgradeFeatureList = {
  MemberAndResourceGroups: {
    icon: usersSolid,
    title: 'Member & Resource Groups',
    description:
      'Member and Resource Groups help larger organizations with data filtering, analysis and access control.',
  },
  GroupWorkloadAndBoardViews: {
    icon: peopleCarrySolid,
    title: 'Group Workload & Board Views',
    description: 'Group-focused views that you can save to your favorites bar and get one-click access to planning.',
  },
  ProjectAccessControls: {
    icon: shieldCheckSolid,
    title: 'Project Access Controls & Roles',
    description:
      'Get more power to work with groups of people and customize access to projects & packages with unique access roles.',
  },
  AccessRoles: {
    icon: keySolid,
    title: 'Single Sign-On (SSO)',
    description: (
      <>
        <ProductName /> provides a centrally managed Single Sign-On (SSO) configuration that integrates <ProductName />{' '}
        with your corporate SSO solution.
      </>
    ),
  },
  CustomDataFields: {
    icon: tagsSolid,
    title: 'Custom Data Fields',
    description:
      'Define your own data to attach to projects, tasks and users so that you can categorize and track in any dimension you like.',
  },
  CustomizableCostCodes: {
    icon: coinSolid,
    title: 'Customizable Cost Codes',
    description:
      'Understand where time goes by using cost codes when tracking time. Associate Rate Sheets with cost codes.',
  },
  CustomFiltering: {
    icon: filterSolid,
    title: 'Custom Filtering',
    description:
      'Views and dashboard widgets use filtering to get to the right insights. Custom filtering includes custom data for more insights.',
  },
  FileStorage: {
    icon: fileSolid,
    title: 'File Storage',
    description: FILE_STORAGE_DESCRIPTION,
  },
  TimesheetReviewAndExport: {
    icon: userClockSolid,
    title: 'Timesheet Management (Review, Export & Lock)',
    description:
      'Features to help managers review, analyze time-tracking data and manage timesheet locks. Export data directly to Excel files.',
  },
  SlackIntegration: {
    icon: slack,
    title: 'Slack Integration',
    description:
      'Stay informed of assignments made to you and changes to your existing work with built-in Slack Integration.',
  },
};

const SectionHeader = ({ text }: { text: ReactNode }) => (
  <div className="upgrade-page__content-section-header">{text}</div>
);

const FeatureCard = ({ featureItem, row, col }: { featureItem: UpgradeFeatureItem; row: number; col: number }) => {
  return (
    <div
      className={`upgrade-page__content-section-grid-feature upgrade-page__content-section-grid-feature-row${row}-col${col}`}
    >
      <LpIcon className="upgrade-page__content-section-grid-feature-icon" icon={featureItem.icon} size="2x" />
      <span className="upgrade-page__content-section-grid-feature-title">{featureItem.title}</span>
      <span className="upgrade-page__content-section-grid-feature-description">{featureItem.description}</span>
    </div>
  );
};

const IncludedBaseFeatures = ({ text }: { text: string }) => (
  <span className="upgrade-page__content-section-included-feature">
    <LpIcon icon={checkSolid} /> {text}
  </span>
);

const UpgradePage = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  useEffect(() => {
    const { uuid } = dispatch(
      OrganizationDao.fetch({ organizationId }, { include: { includeSubscription: true, includePlan: true } })
    );
    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch, organizationId]);

  const sections = [
    { key: 'organization-name', content: <OrganizationLinkCrumb icon={giftSolid} /> },
    { key: NavigationMenuDisplay.Trial, content: NavigationMenuDisplay.Trial },
  ];
  return (
    <>
      <TopNavHeader breadcrumb={<LpBreadcrumb sections={sections} />} />
      <div className="upgrade-page">
        <div className="upgrade-page__max-width">
          <div className="upgrade-page__content">
            <UpgradeFeatureTrialBanner />
            <div className="upgrade-page__content-section">
              <SectionHeader text={'Included in all plans'} />
              <div className="upgrade-page__content-section-included">
                <div className="upgrade-page__content-section-included-left">
                  <IncludedBaseFeatures text={'Automatic Scheduling'} />
                  <IncludedBaseFeatures text={'Task Management'} />
                  <IncludedBaseFeatures text={'Integrated Time-Tracking'} />
                  <IncludedBaseFeatures text={'Change Tracking'} />
                  <IncludedBaseFeatures text={'Alerts & Insights'} />
                </div>
                <div className="upgrade-page__content-section-included-center">
                  <IncludedBaseFeatures text={'Portfolio View'} />
                  <IncludedBaseFeatures text={'Project Priority View'} />
                  <IncludedBaseFeatures text={'Project Board View'} />
                  <IncludedBaseFeatures text={'Project Workload View'} />
                  <IncludedBaseFeatures text={'Project Grid View'} />
                </div>
                <div className="upgrade-page__content-section-included-right">
                  <IncludedBaseFeatures text={'Timesheet Views'} />
                  <IncludedBaseFeatures text={'Personal Work Views'} />
                  <IncludedBaseFeatures text={'Availability Management'} />
                  <IncludedBaseFeatures text={'Customizable Dashboards'} />
                  <IncludedBaseFeatures text={'Portfolio Search'} />
                </div>
              </div>

              <SectionHeader
                text={
                  <span>
                    Premium Features included in trial (
                    <NewWindowLink to={LP_PRICING_URL}>see plan details</NewWindowLink>)
                  </span>
                }
              />
              <div className="upgrade-page__content-section-grid">
                <FeatureCard row={0} col={0} featureItem={upgradeFeaturesList.MemberAndResourceGroups} />
                <FeatureCard row={0} col={1} featureItem={upgradeFeaturesList.GroupWorkloadAndBoardViews} />
                <FeatureCard row={1} col={0} featureItem={upgradeFeaturesList.ProjectAccessControls} />
                <FeatureCard row={1} col={1} featureItem={upgradeFeaturesList.AccessRoles} />
                <FeatureCard row={2} col={0} featureItem={upgradeFeaturesList.CustomDataFields} />
                <FeatureCard row={2} col={1} featureItem={upgradeFeaturesList.CustomizableCostCodes} />
                <FeatureCard row={3} col={0} featureItem={upgradeFeaturesList.CustomFiltering} />
                <FeatureCard row={3} col={1} featureItem={upgradeFeaturesList.TimesheetReviewAndExport} />
                <FeatureCard row={4} col={0} featureItem={upgradeFeaturesList.FileStorage} />
                <FeatureCard row={4} col={1} featureItem={upgradeFeaturesList.SlackIntegration} />
              </div>
            </div>
            <UpgradeFeatureTrialBanner />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradePage;
