import { IntakeWidgetType, ItemType, PackageStatus } from 'daos/enums';
import { Dashboard, Widget } from 'daos/model_types';
import { IntakeWidgetUrlParams } from 'features/dashboards_v2/widget_click_through/intake/types';
import { LibraryLocation } from 'features/library/types';
import { frontend } from 'lib/urls';

export const IntakeTypeDisplay = {
  [IntakeWidgetType.Project]: 'Project',
  [IntakeWidgetType.Task]: 'Task',
};

export const IntakeTypeToItemType = {
  [IntakeWidgetType.Project]: ItemType.PROJECTS,
  [IntakeWidgetType.Task]: ItemType.TASKS,
};

export const getIntakeFormUrl = ({
  dashboard,
  intakeWidgetUrlParams,
  isGuest,
  libraryLocation,
  packageStatus,
}: {
  dashboard: Dashboard;
  intakeWidgetUrlParams: IntakeWidgetUrlParams;
  isGuest: boolean;
  libraryLocation: LibraryLocation | undefined;
  packageStatus: PackageStatus | undefined;
}) => {
  if (isGuest) {
    return frontend.dashboardGuestWidget.url({ ...intakeWidgetUrlParams, dashboardId: dashboard.id });
  }

  switch (libraryLocation) {
    case LibraryLocation.Package:
      return frontend.packageWidgetIntakeForm.url({
        ...intakeWidgetUrlParams,
        packageId: dashboard.itemId,
        dashboardId: dashboard.id,
      });
    case LibraryLocation.Project:
      return frontend.projectWidgetIntakeForm.url({
        ...intakeWidgetUrlParams,
        itemId: dashboard.itemId,
        dashboardId: dashboard.id,
      });
    case LibraryLocation.Collection: {
      if (packageStatus === PackageStatus.SCHEDULED) {
        return frontend.scheduledCollectionWidgetIntakeForm.url({
          ...intakeWidgetUrlParams,
          dashboardId: dashboard.id,
        });
      }
      if (packageStatus === PackageStatus.BACKLOG) {
        return frontend.pendingCollectionWidgetIntakeForm.url({ ...intakeWidgetUrlParams, dashboardId: dashboard.id });
      }
      if (packageStatus === PackageStatus.ARCHIVED) {
        return frontend.archiveCollectionWidgetIntakeForm.url({ ...intakeWidgetUrlParams, dashboardId: dashboard.id });
      }
      break;
    }

    default:
    case LibraryLocation.Workspace:
      return frontend.workspaceWidgetIntakeForm.url({ ...intakeWidgetUrlParams, dashboardId: dashboard.id });
  }
  return '';
};

export const getIntakeSettingsUrl = (
  dashboard: Dashboard,
  widget: Widget,
  libraryLocation: LibraryLocation,
  packageStatus: PackageStatus | null | undefined
) => {
  const { organizationId, workspaceId, id: widgetId } = widget;
  const intakeWidgetUrlParams = { organizationId, workspaceId, widgetId };

  switch (libraryLocation) {
    case LibraryLocation.Package:
      return frontend.packageWidgetIntakeSettings.url({
        ...intakeWidgetUrlParams,
        packageId: dashboard.itemId,
        dashboardId: dashboard.id,
      });
    case LibraryLocation.Project:
      return frontend.projectWidgetIntakeSettings.url({
        ...intakeWidgetUrlParams,
        itemId: dashboard.itemId,
        dashboardId: dashboard.id,
      });
    case LibraryLocation.Collection: {
      if (packageStatus === PackageStatus.SCHEDULED) {
        return frontend.scheduledCollectionWidgetIntakeSettings.url({
          ...intakeWidgetUrlParams,
          dashboardId: dashboard.id,
        });
      }
      if (packageStatus === PackageStatus.BACKLOG) {
        return frontend.pendingCollectionWidgetIntakeSettings.url({
          ...intakeWidgetUrlParams,
          dashboardId: dashboard.id,
        });
      }
      if (packageStatus === PackageStatus.ARCHIVED) {
        return frontend.archiveCollectionWidgetIntakeSettings.url({
          ...intakeWidgetUrlParams,
          dashboardId: dashboard.id,
        });
      }

      return frontend.workspaceWidgetIntakeSettings.url({ ...intakeWidgetUrlParams, dashboardId: dashboard.id });
    }
    case LibraryLocation.Workspace:
    default:
      return frontend.workspaceWidgetIntakeSettings.url({ ...intakeWidgetUrlParams, dashboardId: dashboard.id });
  }
};
