import { customColumnDefinitions } from 'containers/shared/custom_column';
import { addOptionalTextToCustomColumn, nonSortableColumns } from 'containers/shared/custom_column/display_helpers';
import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import { CustomField, TaskStatus } from 'daos/model_types';
import { readonlyArray } from 'lib/readonly_record';

import { SelectionList, SelectionListType } from './types';

export const getColumnSelectionList = ({
  columnOptions,
  customFields,
  taskStatuses,
}: {
  columnOptions: ReadonlyArray<Columns>;
  customFields?: ReadonlyArray<CustomField>;
  taskStatuses?: ReadonlyArray<TaskStatus>;
}) => {
  const columnSelectionList: Array<SelectionList> = [];

  columnOptions.forEach((option) => {
    const displayName = addOptionalTextToCustomColumn(option).trim();
    columnSelectionList.push({
      id: option,
      name: displayName,
      selectionListType: SelectionListType.CustomColumnOptions,
      sortable: !nonSortableColumns.has(option),
    });
  });

  customFields?.forEach((field) => {
    const displayName = `${field.name} (Custom)`;
    columnSelectionList.push({
      id: String(field.id),
      name: displayName,
      selectionListType: SelectionListType.CustomField,
      sortable: true,
    });
  });

  taskStatuses?.forEach((taskStatus) => {
    const displayName = `${taskStatus.name} (Task Status)`;
    columnSelectionList.push({
      id: String(taskStatus.id),
      name: displayName,
      selectionListType: SelectionListType.TaskStatus,
      sortable: true,
    });
  });

  return readonlyArray(columnSelectionList);
};

/*
 * @deprecated This function is deprecated. Use `getColumnSelectionListFromColumnOptionsRefactor` instead.
 */
export const getColumnSelectionListFromColumnOptions = ({
  columnOptions,
  customFields,
  hasFiles,
  canExportTimesheets,
}: {
  columnOptions: ReadonlyArray<string>;
  customFields: Record<string, CustomField>;
  hasFiles: boolean;
  canExportTimesheets: boolean;
}) => {
  const columnSelectionList: Array<SelectionList> = [];

  columnOptions.forEach((option) => {
    const customColumn = option as Columns;

    if (customColumn === StandardColumns.ItemFileCount && !hasFiles) {
      return;
    }

    if (
      (customColumn === StandardColumns.BillingRateSheet || customColumn === StandardColumns.PayRateSheet) &&
      !canExportTimesheets
    ) {
      return;
    }

    if (customColumn === StandardColumns.OnHoldHours) {
      return;
    }

    if (
      customColumn === StandardColumns.LoggedBillable ||
      customColumn === StandardColumns.LoggedNonBillable ||
      customColumn === StandardColumns.StoryPoint ||
      customColumn === StandardColumns.StoryPointSchemeName ||
      customColumn === StandardColumns.StoryPointCountActive ||
      customColumn === StandardColumns.StoryPointCountDone ||
      customColumn === StandardColumns.StoryPointCountOnHold ||
      customColumn === StandardColumns.TimesheetScheduledBillableHours ||
      customColumn === StandardColumns.TimesheetScheduledNonBillableHours ||
      customColumn === StandardColumns.TotalWorkBillable ||
      customColumn === StandardColumns.TotalWorkNonBillable
    ) {
      return;
    }

    const optionAsCustomColumnOptions = option as Columns;

    if (customColumnDefinitions[optionAsCustomColumnOptions]) {
      const displayName = addOptionalTextToCustomColumn(optionAsCustomColumnOptions);
      columnSelectionList.push({
        id: option,
        selectionListType: SelectionListType.CustomColumnOptions,
        name: displayName,
        sortable: !nonSortableColumns.has(optionAsCustomColumnOptions),
      });
    }

    if (customFields[option]) {
      const field = customFields[option];

      if (field) {
        const displayName = `${field.name} (Custom)`;
        columnSelectionList.push({
          id: String(field.id),
          name: displayName,
          selectionListType: SelectionListType.CustomField,
          sortable: true,
        });
      }
    }
  });

  return readonlyArray(columnSelectionList);
};

export const getColumnSelectionListFromColumnOptionsRefactor = ({
  columnOptions,
  customFields,
}: {
  columnOptions: ReadonlyArray<string>;
  customFields: Record<string, CustomField>;
}) => {
  const columnSelectionList: Array<SelectionList> = [];

  columnOptions.forEach((option) => {
    const optionAsCustomColumnOptions = option as Columns;

    if (customColumnDefinitions[optionAsCustomColumnOptions]) {
      const displayName = addOptionalTextToCustomColumn(optionAsCustomColumnOptions);
      columnSelectionList.push({
        id: option,
        selectionListType: SelectionListType.CustomColumnOptions,
        name: displayName,
        sortable: !nonSortableColumns.has(optionAsCustomColumnOptions),
      });
    }

    if (customFields[option]) {
      const field = customFields[option];

      if (field) {
        const displayName = `${field.name} (Custom)`;
        columnSelectionList.push({
          id: String(field.id),
          name: displayName,
          selectionListType: SelectionListType.CustomField,
          sortable: true,
        });
      }
    }
  });

  return readonlyArray(columnSelectionList);
};
