import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Message } from 'semantic-ui-react';

import FeatureTrialBannerWithIcon from 'features/common/feature_trial/feature_trial_banner_with_icon';
import { isPremiumFeaturesTrialAvailable } from 'features/common/feature_trial/helpers';
import { slack } from 'features/common/lp_icon';
import OrganizationConfiguration from 'features/integrations/slack/slack_integration_configuration/organization_configuration';
import OrganizationUserConfiguration from 'features/integrations/slack/slack_integration_configuration/organization_user_configuration';
import SlackIntegrationTopNavHeader from 'features/integrations/slack/slack_integration_top_nav_header';
import useOrganizationFetchOnOrganizationModified from 'hooks/use_organization_fetch_on_organization_modified';
import { ProductName } from 'lib/use_product_name';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import './slack_integration_configuration.scss';
import NotificationSettings from './notification_settings';

const SlackIntegrationConfiguration = () => {
  const organization = useSelector(getCurrentOrganization);
  const organizationUser = useSelector(getCurrentOrganizationUser);
  const location = useLocation();
  const error = new URLSearchParams(location.search).get('error');
  const errorMessage =
    error === 'slack_user_in_use' ? (
      <>
        Another <ProductName /> user is currently connected to the Slack account you chose. Please choose another Slack
        account and try again.
      </>
    ) : null;

  const hasSlackIntegration = organization?.flags.hasSlackIntegration;
  const premiumFeaturesTrialStatus = organization?.flags.premiumFeaturesTrial;

  const showPremiumFeaturesTrialBanner = isPremiumFeaturesTrialAvailable(premiumFeaturesTrialStatus);

  useOrganizationFetchOnOrganizationModified();

  if (!organization || !organizationUser) {
    return null;
  }

  return (
    <div className="slack-integration-configuration">
      {errorMessage && (
        <Message error visible>
          <div>{errorMessage}</div>
        </Message>
      )}
      <SlackIntegrationTopNavHeader />
      {!hasSlackIntegration && showPremiumFeaturesTrialBanner && (
        <FeatureTrialBannerWithIcon
          icon={slack}
          title={'Slack Integration is a Premium Feature'}
          description={
            'Stay informed of new work assigned to you and changes to your existing work by enabling Slack Integration.'
          }
          className={'slack-integration__banner'}
        />
      )}
      <OrganizationConfiguration {...{ organization, organizationUser }} />
      <OrganizationUserConfiguration {...{ organization, organizationUser }} />
      <NotificationSettings {...{ organization, organizationUser }} />
    </div>
  );
};

export default SlackIntegrationConfiguration;
