import moment from 'moment-timezone';
import { Dispatch, SetStateAction } from 'react';
import { string as yupStringValidator } from 'yup';

import { customColumnDefinitions } from 'containers/shared/custom_column';
import { StandardColumns } from 'containers/shared/custom_column/enum';
import { CustomFieldNameBySourceType } from 'containers/shared/custom_field_name';
import { isMultipleValueField } from 'daos/custom_field';
import { CustomFieldType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { CustomFieldEditor } from 'features/common/data_grid/add_edit_grid/cell_editors/custom_fields';
import { CustomFieldEditGridFormatter } from 'features/common/data_grid/add_edit_grid/cell_formatters/custom_fields';
import { customFieldCellClass } from 'features/common/data_grid/add_edit_grid/helpers/class_name_helpers';
import { MultiFieldEditorProps } from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { ItemGridSummaryFormatter } from 'features/common/data_grid/formatters';
import { CustomFieldFormatter } from 'features/common/data_grid/formatters/custom_fields';
import {
  AddEditGridRow,
  EditorAddEditGridProps,
  FormatterAddEditGridProps,
  ItemFormatterProps,
  ItemSummaryFormatterProps,
  PushItemsToJiraErrorByItemId,
} from 'features/common/data_grid/types';
import { COLUMN_WIDTH_S, TEXT_ALIGN_LEFT } from 'lib/constants';

export const customFieldReadOnlyColumns = (customFields: ReadonlyArray<CustomField>) => {
  return customFields.map((field) => {
    return {
      name: <CustomFieldNameBySourceType name={field.name} sourceSystem={field.sourceSystem} />,
      key: `${field.id}`,
      width: COLUMN_WIDTH_S,
      resizable: true,
      sortable: true,
      headerCellClass: TEXT_ALIGN_LEFT,
      cellClass: customColumnDefinitions.customField.customFieldCellClass?.(field.fieldType),
      formatter: (props: ItemFormatterProps) => CustomFieldFormatter(field, props),
      summaryFormatter: (props: ItemSummaryFormatterProps) => (
        <ItemGridSummaryFormatter {...props} customField={field} />
      ),
    };
  });
};

export const customFieldAddEditColumn = (
  customFields: ReadonlyArray<CustomField>,
  setMultiEditModalProps: Dispatch<SetStateAction<MultiFieldEditorProps | undefined>>,
  jiraRequiredErrorsByItemId?: PushItemsToJiraErrorByItemId
) => {
  return customFields.map((field) => {
    const isMultiValueField = isMultipleValueField(field);
    const editorProps = isMultiValueField
      ? {
          editor: () => null,
          editorOptions: { renderFormatter: true },
        }
      : {
          editor: (props: EditorAddEditGridProps) => CustomFieldEditor(field, props),
          editorOptions: { editOnClick: true, commitOnOutsideClick: field.fieldType !== CustomFieldType.DATE },
        };

    return {
      name: field.name,
      key: `${field.id}`,
      cellClass: (row: AddEditGridRow) => {
        const isInvalidCell = !isValidRequiredCustomFieldCell({ row, field, jiraRequiredErrorsByItemId });
        return customFieldCellClass(field, isInvalidCell);
      },
      headerCellClass: TEXT_ALIGN_LEFT,
      formatter: (props: FormatterAddEditGridProps) =>
        CustomFieldEditGridFormatter(field, props, setMultiEditModalProps),
      resizable: true,
      sortable: false,
      width: customColumnDefinitions[StandardColumns.CustomField].widths.grid,
      ...editorProps,
    };
  });
};

const isValidRequiredCustomFieldCell = ({
  row,
  field,
  jiraRequiredErrorsByItemId,
}: {
  row: AddEditGridRow;
  field: CustomField;
  jiraRequiredErrorsByItemId?: PushItemsToJiraErrorByItemId;
}) => {
  if (!jiraRequiredErrorsByItemId) {
    return true;
  }

  const itemId = Object.keys(jiraRequiredErrorsByItemId).find((id) => Number(id) === row.id);
  const rowErrors = jiraRequiredErrorsByItemId[Number(itemId)]?.lpFieldId;

  if (!itemId || !rowErrors) {
    return true;
  }

  if (rowErrors.includes(field.id)) {
    const customField = row[field.id];
    if (!customField) {
      return false;
    }
    const fieldType = customField?.[0]?.fieldType;
    switch (fieldType) {
      case CustomFieldType.DATE:
        return moment(customField?.[0]?.date).isValid();
      case CustomFieldType.NUMERIC:
        return typeof customField?.[0]?.number === 'number';
      case CustomFieldType.TEXT:
        return typeof customField?.[0]?.text === 'string';
      case CustomFieldType.PICKLIST:
        return Boolean(customField?.[0]?.picklistChoice?.id);
      case CustomFieldType.MULTI_PICKLIST:
        return Boolean(customField?.[0]?.picklistChoice?.id);
      case CustomFieldType.LINK:
        return yupStringValidator().url().isValidSync(customField?.[0]?.url);
      default:
        return false;
    }
  }

  return true;
};
