import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DashboardDao } from 'daos/dashboards';
import { WidgetGroupDao } from 'daos/widget_groups';
import { EntityNotFound, EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { getDashboardForId } from 'features/dashboards/selectors';
import { DashboardContext, defaultDashboardContextValues } from 'features/dashboards_v2/context';
import { DashboardRenderer } from 'features/dashboards_v2/dashboard';
import { DashboardGuestHeader } from 'features/guest/dashboard/header';
import { awaitRequestFinish } from 'lib/api';
import { getCurrentUserHasGuestAccessToDashboard } from 'redux/entities/selectors/dashboard_guest';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

export const GuestDashboard = () => {
  const { organizationId, workspaceId, dashboardId } = useParams<{
    organizationId: string;
    workspaceId: string;
    dashboardId: string;
  }>();

  const dashboard = useSelector((state) => getDashboardForId(state, dashboardId)) ?? null;

  const { fetchGuestDashboard, isLoading } = useFetchGuestDashboard({ dashboardId, organizationId, workspaceId });

  useEffect(() => {
    fetchGuestDashboard();
  }, [fetchGuestDashboard]);

  const isCurrentWorkspaceUserGuest = useSelector(getIsGuestCurrentWorkspaceUser);
  const hasGuestAccessToDashboard = useSelector((state) =>
    getCurrentUserHasGuestAccessToDashboard(state, organizationId, workspaceId, dashboardId)
  );

  if (isCurrentWorkspaceUserGuest && !hasGuestAccessToDashboard) {
    return (
      <EntityNotFound
        entityType={EntityTypeNotFound.Dashboard}
        setEntityNotFound={noop}
        setFetchingCurrentUser={noop}
      />
    );
  }

  return (
    <DashboardContext.Provider
      value={{
        ...defaultDashboardContextValues,
        dashboard,
        isGuest: true,
        isLoading,
      }}
    >
      <DashboardRenderer
        header={<DashboardGuestHeader dashboardId={dashboardId} organizationId={organizationId} />}
        dashboardId={dashboardId}
      />
    </DashboardContext.Provider>
  );
};

function useFetchGuestDashboard({
  dashboardId,
  organizationId,
  workspaceId,
}: {
  dashboardId: string;
  organizationId: string;
  workspaceId: string;
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchGuestDashboard = useCallback(() => {
    setIsLoading(true);
    const { uuid } = dispatch(
      DashboardDao.fetch(
        { organizationId, workspaceId, dashboardId },
        {
          include: {
            includeLibraryResources: true,
            includeWidgetsMeta: true,
          },
        }
      )
    );

    dispatch(
      WidgetGroupDao.fetch({
        dashboardId: String(dashboardId),
        organizationId: Number(organizationId),
        workspaceId: Number(workspaceId),
      })
    );

    dispatch(awaitRequestFinish(uuid, { onFinish: () => setIsLoading(false) }));
  }, [dashboardId, dispatch, organizationId, workspaceId]);

  return { fetchGuestDashboard, isLoading };
}
