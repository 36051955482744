import { useFormikContext } from 'formik';
import { SyntheticEvent, useMemo } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraProjectModalPicklistData,
} from 'features/jira_project/modal/types';
import { DEFAULT_JIRA_HIGH_ESTIMATE_PERCENTAGE, DEFAULT_JIRA_LOW_ESTIMATE_PERCENTAGE, SELECT_ALL } from 'lib/constants';

import 'features/common/custom_fields/custom_field_value/custom_field_value.scss';

interface PicklistDropdownProps {
  disabled?: boolean;
  picklist: ReadonlyArray<JiraProjectModalPicklistData>;
  onClose?: () => void;
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => void;
  canSelectAll?: boolean;
  renderIcon?: boolean;
  placeholder?: string;
  name: JiraProjectModalFormFields;
  fieldChoiceId?: string | number;
}

const noneOption: DropdownItemProps = { key: 'NONE', text: <span> None </span>, value: undefined };

const selectAllOption: Array<DropdownItemProps> = [
  {
    key: SELECT_ALL,
    text: <span> {SELECT_ALL} </span>,
    value: SELECT_ALL,
  },
];

export const JiraProjectModalPicklist = ({
  disabled = false,
  picklist,
  onClose,
  placeholder = 'Add',
  name,
  onChange,
  canSelectAll,
  fieldChoiceId,
}: PicklistDropdownProps) => {
  const { setFieldError, setFieldValue, values, errors } = useFormikContext<JiraProjectModalFormValues>();
  const { setJiraProjects } = useJiraProjectModalContext();
  const error = !!errors[name as keyof JiraProjectModalFormValues];

  const picklistOptions = useMemo(() => {
    const picklistOptions = picklist.map((pick) => ({
      key: pick.name?.toLowerCase(),
      text: <div className="jira-project-modal__content-table-picklist-option">{pick.name}</div>,
      disabled: pick?.disabled,
      search: pick.name?.toLowerCase(),
      value: pick.id,
    }));

    return [noneOption, ...(canSelectAll ? selectAllOption : []), ...picklistOptions];
  }, [picklist, canSelectAll]);

  const handleChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (onChange !== undefined) {
      return onChange(_, data as { value: string });
    }

    setFieldError(name, undefined);
    setFieldValue(name, data.value ?? '');
    setJiraProjects([]);
    setFieldValue(JiraProjectModalFormFields.JiraProjectId, '');
    setFieldValue(JiraProjectModalFormFields.IssueTypeIds, []);
    setFieldValue(JiraProjectModalFormFields.IssueStatusIds, []);
    setFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, []);
    setFieldValue(JiraProjectModalFormFields.IssueCreatedDate, '');
    setFieldValue(JiraProjectModalFormFields.LowEstimate, DEFAULT_JIRA_LOW_ESTIMATE_PERCENTAGE);
    setFieldValue(JiraProjectModalFormFields.HighEstimate, DEFAULT_JIRA_HIGH_ESTIMATE_PERCENTAGE);
    setFieldValue(JiraProjectModalFormFields.SyncJiraWorklog, false);
    setFieldValue(JiraProjectModalFormFields.SyncTimesheetWorklog, false);
    setFieldValue(JiraProjectModalFormFields.CostCodeId, null);
    setFieldValue(JiraProjectModalFormFields.FieldMappings, []);
  };

  const getValue = () => {
    if (fieldChoiceId) {
      return fieldChoiceId;
    }
    const value = values[name];
    if (Array.isArray(value)) {
      return '';
    }
    return value ?? '';
  };

  return (
    <PortalDropdown>
      <Dropdown
        data-testid={`lp-form-dropdown-${name}`}
        className="jira-project-modal__picklist"
        disabled={disabled}
        lazyLoad
        error={error}
        search={lpDropdownSearch}
        onChange={handleChange}
        options={picklistOptions}
        placeholder={placeholder}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        value={getValue()}
        onClose={onClose}
      />
    </PortalDropdown>
  );
};
