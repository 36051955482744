import { useSelector } from 'react-redux';

import { FeatureFlag } from 'lib/feature_flags';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getSystemFeatureFlagDefaults, getSystemFeatureFlagValues } from 'redux/entities/selectors/system_settings';

export const useHasFeature = (feature: FeatureFlag) => {
  const organization = useSelector(getCurrentOrganization);
  const featureFlagDefaults = useSelector(getSystemFeatureFlagDefaults);

  return (organization?.featureFlags ?? featureFlagDefaults)[feature];
};

export const useHasSystemFeature = (feature: FeatureFlag) => useSelector(getSystemFeatureFlagValues)[feature];
