import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { Organization, OrganizationUser } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { awaitRequestFinish } from 'lib/api';
import LpSemiBoldText from 'lib/display_helpers/lp_semi_bold_text';
import { ProductName } from 'lib/use_product_name';
import { getOrganizationUserSlackIntegrationsForOrgId } from 'redux/entities/selectors/organization_user_slack_integration';
import { removeEntities } from 'redux/entities/slice';

import './slack_integration_configuration.scss';

interface OrganizationConfigurationProps {
  organization: Organization;
  organizationUser: OrganizationUser;
}

const OrganizationConfiguration = ({ organization, organizationUser }: OrganizationConfigurationProps) => {
  const dispatch = useDispatch();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const orgUserSlackIntegrationIds = useSelector((state) =>
    getOrganizationUserSlackIntegrationsForOrgId(state, organization.id)
  ).map(({ id }) => id);

  if (!organizationUser.admin) {
    return null;
  }

  const hasSlackIntegration = !organization?.flags.hasSlackIntegration;

  const onEnableSlackIntegration = () => {
    dispatch(OrganizationDao.update({ organizationId: organization.id }, { slackApproved: true }));
  };

  const onDisableSlackIntegration = () => {
    const { uuid } = dispatch(OrganizationDao.update({ organizationId: organization.id }, { slackApproved: false }));

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () =>
          dispatch(
            removeEntities({ entityType: 'organizationUserSlackIntegrations', ids: orgUserSlackIntegrationIds })
          ),
      })
    );

    setShowConfirmationDialog(false);
  };

  const onOpenConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const onCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <div>
      <h3>Slack user connections to {organization.name}</h3>
      <div className="slack-integration-configuration__connect-to-slack-section">
        {organization.slackApproved ? (
          <Button negative onClick={onOpenConfirmationDialog}>
            Disable Slack Integration
          </Button>
        ) : (
          <>
            <Button primary disabled={hasSlackIntegration} onClick={onEnableSlackIntegration}>
              Enable Slack Integration
            </Button>
            <em>
              Licensed users will be able to get <ProductName /> notifications in Slack.
            </em>
          </>
        )}
      </div>

      {showConfirmationDialog && (
        <BaseConfirmModal
          confirmButtonText={'Disable Slack Integration'}
          content={
            <p>
              Slack Integration will be disabled for{' '}
              <LpSemiBoldText
                className="slack-integration-configuration__disable-modal-text--italic"
                text={'all users'}
              />{' '}
              in the{' '}
              <LpSemiBoldText
                className="slack-integration-configuration__disable-modal-text--italic"
                text={organization.name}
              />{' '}
              Org and they will stop receiving <ProductName /> notifications in Slack. All active user connections will
              be disconnected and deleted.
            </p>
          }
          onClose={onCloseConfirmationDialog}
          onConfirm={onDisableSlackIntegration}
        />
      )}
    </div>
  );
};

export default OrganizationConfiguration;
