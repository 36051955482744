import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { forOwn, isNil, omit } from 'lodash';
import isEqual from 'react-fast-compare';
import { isPrimitive } from 'utility-types';

import { Iteration, StoryPointSchemeOwner } from 'daos/model_types';
import { resetRootState } from 'redux/root_actions';
import { deleteBulkEntityFinished, deleteEntityFinished } from 'redux/sagas/api_saga/delete_actions';
import { requestFinishedSuccess } from 'redux/sagas/api_saga/request_actions';

import { EntityLookupById, EntityState } from './types';

export const initialState: EntityState = {
  academyCourseProgress: {},
  academyCourses: {},
  academyLessonProgress: {},
  academyLessons: {},
  academyOutpostTrackLessons: {},
  academyProgress: {},
  academyTrackLessons: {},
  academyTrackProgress: {},
  academyTracks: {},
  alerts: {},
  availabilityRanges: {},
  banners: {},
  changes: {},
  costCodes: {},
  dashboards: {},
  dashboardGuests: {},
  deletedItems: {},
  dependencies: {},
  discussions: {},
  discussionSubscriptions: {},
  estimates: {},
  externalIntegrations: {},
  favorites: {},
  fieldValues: {},
  fields: {},
  files: {},
  filterAwareItemMetrics: {},
  filterAwareDateRange: {},
  groups: {},
  groupAvailability: {},
  groupAllocations: {},
  inAppNotifications: {},
  itemAccessForGroupGrids: {},
  itemAccessForWorkspaceUserGrids: {},
  itemAcls: {},
  itemEffectiveFieldValues: {},
  itemMetrics: {},
  itemPins: {},
  items: {},
  intakeForms: {},
  itemFiles: {},
  iterations: {},
  iterationMetrics: {},
  jiraAccessibleResourcesView: {},
  externalIntegrationFieldMappings: {},
  jiraIssueFilters: {},
  jiraCredentials: {},
  jiraSyncSettings: {},
  logins: {},
  libraryResources: {},
  myWorks: {},
  oauthCredentials: {},
  organizationProjectTypes: {},
  organizationUsers: {},
  organizationUserSlackIntegrations: {},
  organizationUserEmailNotifications: {},
  organizations: {},
  picklistChoices: {},
  plans: {},
  portfolios: {},
  rateData: {},
  rateSheets: {},
  rateRules: {},
  schedulingLimits: {},
  storyPoints: {},
  storyPointSchemes: {},
  storyPointSchemeOwners: {},
  subjectAccessForItemGrids: {},
  subscriptions: {},
  syncProjectJobs: {},
  systemSettings: {},
  taskStatuses: {},
  timesheetEntries: {},
  timesheetEntryLocks: {},
  timesheetEntryLockExceptions: {},
  timesheetReviewUsers: {},
  timesheetReviews: {},
  timesheets: {},
  timesheetRollups: {},
  users: {},
  widgetGroups: {},
  widgets: {},
  widgetTotalRows: {},
  workloads: {},
  workspaceUserGroups: {},
  workspaceUsers: {},
  workspaces: {},
  userWorkspaceSettings: {},
};

const entitySlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    mergeEntities: (state, action: PayloadAction<Partial<EntityState>>) => {
      return castDraft(deepMergeEntityState(state, action.payload));
    },
    purgeAcademyOutposts: (state) => {
      state.academyOutpostTrackLessons = {};
    },
    purgeAlerts: (state) => {
      state.alerts = {};
    },
    purgeDependencies: (state) => {
      state.dependencies = {};
    },
    purgeEntities: () => initialState,
    purgeTimesheetReviewUsers: (state) => {
      state.timesheetReviewUsers = {};
    },
    purgeTimesheets: (state) => {
      state.timesheets = {};
    },
    purgeWorkloads: (state) => {
      state.workloads = {};
    },
    purgeWorkspaceUserGroups: (state) => {
      state.workspaceUserGroups = {};
    },
    purgeStoryPointSchemeOwners: (state) => {
      state.storyPointSchemeOwners = {};
    },
    removeEntity: (state, action: PayloadAction<{ id: number; entityType: keyof EntityState }>) => {
      return castDraft(omitEntitiesById(state, action.payload.entityType, [action.payload.id]));
    },
    removeEntities: (state, action: PayloadAction<{ ids: ReadonlyArray<number>; entityType: keyof EntityState }>) => {
      const { ids, entityType } = action.payload;

      return castDraft(omitEntitiesById(state, entityType, ids));
    },
    replaceIterations: (state, action: PayloadAction<{ iterations: ReadonlyArray<Iteration> }>) => {
      state.iterations = action.payload.iterations.reduce((acc: EntityLookupById<Iteration>, iteration) => {
        return { ...acc, [iteration.id]: iteration };
      }, {});
    },
    replaceStoryPointSchemeOwners: (state, action: PayloadAction<ReadonlyArray<StoryPointSchemeOwner>>) => {
      state.storyPointSchemeOwners = action.payload.reduce(
        (acc: EntityLookupById<StoryPointSchemeOwner>, schemeOwner) => {
          return { ...acc, [schemeOwner.id]: schemeOwner };
        },
        {}
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestFinishedSuccess, (state, action) => {
        const skipEntityReducer = !!action.payload.meta?.skipReduce;
        const entities = action.payload.resultPayload?.entities;

        if (!skipEntityReducer) {
          if (entities) {
            return castDraft(deepMergeEntityState(state, entities));
          }
        }
      })
      .addCase(deleteEntityFinished, (state, action) => {
        const { entityType, id } = action.payload;

        return castDraft(omitEntitiesById(state, entityType, [id]));
      })
      .addCase(deleteBulkEntityFinished, (state, action) => {
        const { entityType, ids } = action.payload;

        return castDraft(omitEntitiesById(state, entityType, ids));
      })
      .addCase(resetRootState, () => initialState);
  },
});

export const {
  mergeEntities,
  purgeAcademyOutposts,
  purgeAlerts,
  purgeDependencies,
  purgeEntities,
  purgeTimesheetReviewUsers,
  purgeTimesheets,
  purgeWorkloads,
  purgeWorkspaceUserGroups,
  purgeStoryPointSchemeOwners,
  removeEntities,
  removeEntity,
  replaceIterations,
  replaceStoryPointSchemeOwners,
} = entitySlice.actions;

export default entitySlice.reducer;

function omitEntitiesById(state: EntityState, entityType: keyof EntityState, ids: ReadonlyArray<number>): EntityState {
  if (Object.prototype.hasOwnProperty.call(state, entityType)) {
    const existingValue = state[entityType];
    if (!isPrimitive(existingValue)) {
      return { ...state, ...{ [entityType]: omit(existingValue, ids) } };
    }
  }

  return state;
}

function deepMergeEntityState(existingState: EntityState, newState: Partial<EntityState> | undefined): EntityState {
  if (!newState) {
    return existingState;
  }

  const mergedState: Record<string, unknown> = { ...existingState };
  let mergedStateDiffers = false;

  // iterate over the top level properties in entity state ("alerts", "items", "groups", etc)
  forOwn(newState, (newValue, key) => {
    // Skip over any null/undefined properties.
    // Skip any unknown entities that aren't declared in the EntityState interface to avoid crashes.
    if (key in existingState && !isNil(newValue)) {
      //merge an EntityLookupById properties like "alerts", "items", "groups", etc.
      const existingEntitiesById = existingState[key as keyof EntityState] as EntityLookupById<unknown>;
      const newEntitiesById = newValue as EntityLookupById<unknown>;
      const mergedEntitiesById: Record<string, unknown> = { ...existingEntitiesById };

      let hasNewOrChangedEntities = false;

      // Don't replace individual entities if they're identical to what's already in the store (via a deep equality
      // check). This happens a lot when we re-fetch data from the API that hasn't changed.
      forOwn(newEntitiesById, (newEntity, id) => {
        const existingEntity = existingEntitiesById[id];
        if (!isEqual(newEntity, existingEntity)) {
          mergedEntitiesById[id] = newEntity;
          hasNewOrChangedEntities = true;
        }
      });

      if (hasNewOrChangedEntities) {
        mergedStateDiffers = true;
        mergedState[key] = mergedEntitiesById;
      }
    }
  });

  if (mergedStateDiffers) {
    return mergedState as unknown as EntityState;
  } else {
    return existingState;
  }
}
