import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { LpIcon, starExclamationSolid } from 'features/common/lp_icon';
import { ProductName } from 'lib/use_product_name';

export const InvitationExpiredOrRevoked = () => (
  <div className="invitation-expired-or-revoked__wrapper">
    <div className="invitation-expired-or-revoked__content">
      <div className="invitation-expired-or-revoked">
        <LpIcon className="invitation-expired-or-revoked__icon" icon={starExclamationSolid} />
        <h1>Whoops!</h1>
        <span className="invitation-expired-or-revoked__text">
          Your email invitation to <ProductName /> is no longer valid. You&apos;ll just need to be invited again to
          start planning!
        </span>
      </div>
    </div>

    <LpLegalTermsFooter />
  </div>
);
