import { useFormikContext } from 'formik';
import { SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { TaskPushSetting } from 'daos/external_integration_enums';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { jira, LpIcon } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { FieldMappingRowType, FieldMappingValue, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { useHasFeature, useHasSystemFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

import 'features/common/custom_fields/custom_field_value/custom_field_value.scss';

const noneOption: Array<DropdownItemProps> = [{ key: 'NONE', text: <span> None </span>, value: undefined }];

interface PicklistDropdownProps {
  disabled?: boolean;
  picklist: ReadonlyArray<FieldMappingValue>;
  onClose?: () => void;
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => void;
  rowType?: FieldMappingRowType;
  nameMapping: FieldMappingValue;
  isNameColumn?: boolean;
  fieldChoiceId?: string | number;
  showNoneOption?: boolean;
}

const getIcon = ({
  rowType,
  isNameColumn,
  isRequired = false,
  hasTempoBrandingFeature,
}: {
  rowType?: FieldMappingRowType;
  isNameColumn: boolean;
  hasTempoBrandingFeature?: boolean;
  isRequired?: boolean;
}) => {
  const jiraIcon = isRequired ? <LpSvg icon={LpSvgs.JIRA_BLUE_GRADIENT} size={17} /> : <LpIcon icon={jira} size="lg" />;
  const lpIcon = (
    <LpSvg
      icon={hasTempoBrandingFeature ? LpSvgs.PORTFOLIO_MANAGER_WAVY : LpSvgs.LP_WAVY}
      size={hasTempoBrandingFeature ? 19 : 15}
    />
  );
  if (rowType === FieldMappingRowType.LpToJira) {
    return isNameColumn ? lpIcon : jiraIcon;
  }
  return isNameColumn ? jiraIcon : lpIcon;
};

const getPicklistOptions = ({
  picklist,
  isNameColumn,
  rowType,
  showNoneOption,
  hasJiraIssueCreationFeature,
  hasTempoBrandingFeature,
  hasManualPushSetting,
}: {
  picklist: ReadonlyArray<FieldMappingValue>;
  isNameColumn: boolean;
  rowType?: FieldMappingRowType;
  showNoneOption?: boolean;
  hasJiraIssueCreationFeature?: boolean;
  hasTempoBrandingFeature?: boolean;
  hasManualPushSetting?: boolean;
}) => {
  const picklistOptions = picklist.map((pick) => {
    const isRequired =
      hasJiraIssueCreationFeature &&
      hasManualPushSetting &&
      pick.isMandatoryOnCreationFor &&
      pick.isMandatoryOnCreationFor.length > 0;
    const icon = getIcon({
      rowType,
      isNameColumn,
      isRequired,
      hasTempoBrandingFeature,
    });
    return {
      key: pick.id,
      text: (
        <div className="jira-project-modal__content-table-picklist-option">
          {icon}
          {pick.name}
        </div>
      ),
      search: pick.name?.toLowerCase(),
      value: pick.id,
    };
  });

  return [...(showNoneOption ? noneOption : []), ...picklistOptions];
};

export const JiraProjectModalFieldMappingPicklist = ({
  disabled = false,
  isNameColumn = true,
  picklist,
  onClose,
  rowType,
  nameMapping,
  onChange,
  fieldChoiceId,
  showNoneOption = false,
}: PicklistDropdownProps) => {
  const hasJiraIssueCreationFeature = useHasFeature(FeatureFlag.jiraIssueCreation);
  const hasTempoBrandingFeature = useHasSystemFeature(FeatureFlag.tempoBranding);
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const { taskPushSetting } = values;
  const hasManualPushSetting = taskPushSetting === TaskPushSetting.Manual;

  const options = getPicklistOptions({
    picklist,
    isNameColumn,
    rowType,
    showNoneOption,
    hasJiraIssueCreationFeature,
    hasTempoBrandingFeature,
    hasManualPushSetting,
  });

  const handleChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (onChange !== undefined) {
      return onChange(_, data as { value: string });
    }
  };

  return (
    <PortalDropdown>
      <Dropdown
        data-testid={`lp-form-dropdown-${nameMapping.name}`}
        className="jira-project-modal__picklist"
        disabled={disabled}
        lazyLoad
        search={lpDropdownSearch}
        onChange={handleChange}
        options={options}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        value={fieldChoiceId}
        onClose={onClose}
        placeholder="Add"
      />
    </PortalDropdown>
  );
};
