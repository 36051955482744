import { ReactNode } from 'react';

import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

enum ForgotPasswordErrors {
  InvalidEmail = 'record_not_found',
}

export const forgotPasswordErrors = (field: string) => {
  let forgotPasswordError: string | ReactNode | undefined;

  switch (field) {
    case ForgotPasswordErrors.InvalidEmail: {
      forgotPasswordError = (
        <>
          {'This email is not in our system. Try again, or '}
          <span>
            <LpLink to={frontend.trial.url({})}>{'Sign up now'}</LpLink>
            {'.'}
          </span>
        </>
      );
      return forgotPasswordError;
    }

    default: {
      forgotPasswordError = (
        <>
          Contact <ProductName /> support.
        </>
      );
      return forgotPasswordError;
    }
  }
};
