import { Columns, FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { CustomFieldType } from 'daos/enums';
import {
  COLUMN_WIDTH_L,
  COLUMN_WIDTH_M,
  COLUMN_WIDTH_S,
  COLUMN_WIDTH_XL,
  COLUMN_WIDTH_XXL,
  TEXT_ALIGN_LEFT,
  TEXT_ALIGN_RIGHT,
} from 'lib/constants';

const defaultListWidths = {
  minWidth: '8rem',
  maxWidth: undefined,
};

export const defaultWidths = {
  grid: COLUMN_WIDTH_S,
  lists: defaultListWidths,
};

interface CustomColumn {
  displayText: string;
  systemCellClass: string;
  sortable: boolean;
  customFieldCellClass?: (fieldType?: CustomFieldType | null) => string;
  widths: {
    grid: number;
    lists: {
      minWidth: string;
      maxWidth: string | undefined;
    };
  };
}

const getCustomFieldCellStyle = (fieldType?: CustomFieldType | null) => {
  switch (fieldType) {
    case CustomFieldType.CURRENCY:
    case CustomFieldType.NUMERIC:
      return TEXT_ALIGN_RIGHT;
    default:
      return TEXT_ALIGN_LEFT;
  }
};

export const customColumnDefinitions: Record<Columns, CustomColumn> = {
  [StandardColumns.Assigned]: {
    displayText: 'Assigned To',
    systemCellClass: TEXT_ALIGN_LEFT,
    sortable: true,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '10rem',
        maxWidth: '15rem',
      },
    },
  },
  [FrontendColumns.Empty]: {
    displayText: '',
    sortable: true,
    systemCellClass: '',
    widths: defaultWidths,
  },
  [StandardColumns.Workload]: {
    displayText: 'Workload',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.UserType]: {
    displayText: 'User Type',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.UserStatus]: {
    displayText: 'User Status',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.Username]: {
    displayText: 'Username',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.UploadedBy]: {
    displayText: 'Uploaded By',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.UploadedOn]: {
    displayText: 'Uploaded On',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.UnloggedWork]: {
    displayText: 'Unlogged (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TotalAvailability]: {
    displayText: 'Total Availability (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [FrontendColumns.SlackWorkspaceName]: {
    displayText: 'Connected To',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.Settings]: {
    displayText: 'Settings',
    sortable: false,
    systemCellClass: 'file-action-ellipsis',
    widths: defaultWidths,
  },
  [FrontendColumns.RollupLatestTargetFinish]: {
    displayText: 'Rollup Latest Target Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.RollupEarliestActiveTargetFinish]: {
    displayText: 'Rollup Earliest Active Target Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.RollupEarliestTargetStart]: {
    displayText: 'Rollup Earliest Target Start',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.CustomField]: {
    displayText: 'Data Field',
    sortable: true,
    systemCellClass: '',
    customFieldCellClass: getCustomFieldCellStyle,
    widths: defaultWidths,
  },
  [StandardColumns.RemainingAvailability]: {
    displayText: 'Available (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: defaultListWidths,
    },
  },
  [FrontendColumns.PercentCompleteNumericValueAsString]: {
    displayText: '% Complete',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [FrontendColumns.OnHoldHoursRange]: {
    displayText: 'On Hold Hours (Range)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [FrontendColumns.OrgUserName]: {
    displayText: 'Connected Users',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.FirstName]: {
    displayText: 'First Name',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.LpUserId]: {
    displayText: 'LP User ID',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.MetricsHelp]: {
    displayText: 'Metrics Help',
    sortable: true,
    systemCellClass: '',
    widths: defaultWidths,
  },
  [FrontendColumns.None]: {
    displayText: 'None',
    sortable: true,
    systemCellClass: '',
    widths: defaultWidths,
  },
  [StandardColumns.Groups]: {
    displayText: 'Groups',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.LastName]: {
    displayText: 'Last Name',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.LatestDoneDate]: {
    displayText: 'Latest Done Date',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.AvailabilityUsed]: {
    displayText: '% Availability Used',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [FrontendColumns.EarliestDoneDate]: {
    displayText: 'Earliest Done Date',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.EffectiveTargetFinish]: {
    displayText: 'Effective Target Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.EffectiveTargetStart]: {
    displayText: 'Effective Target Start',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [FrontendColumns.FileName]: {
    displayText: 'File Name',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [FrontendColumns.FileSize]: {
    displayText: 'File Size',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.Assignment]: {
    displayText: 'Assignment',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_L,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.AssignmentCountActive]: {
    displayText: 'Active Assignments',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.AssignmentCountDone]: {
    displayText: 'Done Assignments',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.AssignmentCountTotal]: {
    displayText: 'Total Assignments',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.AssignmentDone]: {
    displayText: 'Assignment Done',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.BenchmarkEstimate]: {
    displayText: 'Benchmark Estimate (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.ClippedEffort]: {
    displayText: 'Clipped Hours',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.CostCode]: {
    displayText: 'Cost Code',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.CreatedAt]: {
    displayText: 'Created On',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.CreatedBy]: {
    displayText: 'Created By',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.DeletedAt]: {
    displayText: 'Deleted At',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.Description]: {
    displayText: 'Description',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '10rem',
        maxWidth: '15rem',
      },
    },
  },
  [StandardColumns.DoneDate]: {
    displayText: 'Done Date',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.ExpectedFinish]: {
    displayText: 'Expected Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9.5rem',
        maxWidth: '9.5rem',
      },
    },
  },
  [StandardColumns.ExpectedStart]: {
    displayText: 'Expected Start',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9.5rem',
        maxWidth: '9.5rem',
      },
    },
  },
  [StandardColumns.FinishRange]: {
    displayText: 'Finish Range',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '11rem',
        maxWidth: undefined,
      },
    },
  },
  [StandardColumns.FolderStatus]: {
    displayText: 'Folder Status',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.HighEstimate]: {
    displayText: 'High Estimate (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.LowEstimate]: {
    displayText: 'Low Estimate (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.LatestFinish]: {
    displayText: 'Latest Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.Location]: {
    displayText: 'Location',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_XXL,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.Logged]: {
    displayText: 'Logged Hours',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.LoggedBillable]: {
    displayText: 'Logged Billable (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.LoggedNonBillable]: {
    displayText: 'Logged Non-Billable (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.Name]: {
    displayText: 'Name',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_XXL,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.OnHoldHours]: {
    displayText: 'On Hold Hours (E)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.Package]: {
    displayText: 'Package',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_L,
      lists: defaultListWidths,
    },
  },
  [FrontendColumns.PackageStatus]: {
    displayText: 'Package Status',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.PercentComplete]: {
    displayText: '% Complete',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.PercentTasksComplete]: {
    displayText: '% Tasks Complete',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.PriorityRush]: {
    displayText: 'Schedule Priority',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.Project]: {
    displayText: 'Project',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_L,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.ProjectCountScheduled]: {
    displayText: 'Scheduled Projects',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.ProjectCountDone]: {
    displayText: 'Done Projects',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.ProjectCountOnHold]: {
    displayText: 'On Hold Projects',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.ProjectCountTotal]: {
    displayText: 'Total Projects',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.ProjectStatus]: {
    displayText: 'Project Status',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.ItemFileCount]: {
    displayText: 'File Count',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.ItemId]: {
    displayText: 'LP ID',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.BillingRateSheet]: {
    displayText: 'Billing Rate Sheet',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.PayRateSheet]: {
    displayText: 'Pay Rate Sheet',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.RemainingWorkExpected]: {
    displayText: 'Remaining Estimate (E)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.RemainingWorkRange]: {
    displayText: 'Remaining Range (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.RemainingWorkLow]: {
    displayText: 'Remaining Estimate (Low)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.RemainingWorkHigh]: {
    displayText: 'Remaining Estimate (High)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.ScheduleBar]: {
    displayText: 'Predictive Schedule',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_L,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.SubFolder]: {
    displayText: 'Sub-Folder',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_L,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.StoryPoint]: {
    displayText: 'Story Points',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.StoryPointSchemeName]: {
    displayText: 'Story Point Scheme Name',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.StoryPointCountActive]: {
    displayText: 'Active Story Points',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.StoryPointCountDone]: {
    displayText: 'Done Story Points',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.StoryPointCountOnHold]: {
    displayText: 'On Hold Story Points',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TargetFinish]: {
    displayText: 'Target Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.TargetFinishDelta]: {
    displayText: 'Target Finish Delta',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TargetFinishType]: {
    displayText: 'At Finish',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.TargetStart]: {
    displayText: 'Target Start',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.TargetStartDelta]: {
    displayText: 'Target Start Delta',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.Task]: {
    displayText: 'Task',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_L,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.TaskCountActive]: {
    displayText: 'Active Tasks',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TaskCountDone]: {
    displayText: 'Done Tasks',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TaskCountOnHold]: {
    displayText: 'On Hold Tasks',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TaskCountTotal]: {
    displayText: 'Total Tasks',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TaskStatus]: {
    displayText: 'Task Status',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.TimesheetScheduled]: {
    displayText: 'Scheduled Hours',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.TimesheetScheduledNonBillableHours]: {
    displayText: 'Scheduled Non-Billable (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.TimesheetScheduledBillableHours]: {
    displayText: 'Scheduled Billable (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.TotalWorkBillable]: {
    displayText: 'Total Work Billable (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.TotalWorkNonBillable]: {
    displayText: 'Total Work Non-Billable (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '7rem',
        maxWidth: '7rem',
      },
    },
  },
  [StandardColumns.TotalWorkScheduled]: {
    displayText: 'Total Work Scheduled (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_M,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.TotalWorkExpected]: {
    displayText: 'Total Work Expected (h)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.TotalWorkRange]: {
    displayText: 'Total Work (Range)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TotalWorkLow]: {
    displayText: 'Total Work (Low)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TotalWorkHigh]: {
    displayText: 'Total Work (High)',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.TrackingOnly]: {
    displayText: 'Tracking Only',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.UncertainWork]: {
    displayText: 'Uncertain Hours',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.UnusedEffortExpected]: {
    displayText: 'Unused Hours',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.UpdatedAt]: {
    displayText: 'Updated At',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.UpdatedBy]: {
    displayText: 'Updated By',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.WorkType]: {
    displayText: 'Assignment Scheduling',
    sortable: true,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_S,
      lists: {
        minWidth: '9rem',
        maxWidth: '9rem',
      },
    },
  },
  [StandardColumns.WorkLimit]: {
    displayText: 'Work Limit',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.WorkLimitDelta]: {
    displayText: 'Work Limit Delta',
    sortable: true,
    systemCellClass: TEXT_ALIGN_RIGHT,
    widths: defaultWidths,
  },
  [StandardColumns.DateRange]: {
    displayText: 'Date Range',
    sortable: false,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: {
      grid: COLUMN_WIDTH_XL,
      lists: defaultListWidths,
    },
  },
  [StandardColumns.IterationId]: {
    displayText: 'Iteration ID',
    sortable: false,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
  [StandardColumns.IterationStatus]: {
    displayText: 'Iteration Status',
    sortable: false,
    systemCellClass: TEXT_ALIGN_LEFT,
    widths: defaultWidths,
  },
};
