import classNames from 'classnames';
import { DropdownItemProps } from 'semantic-ui-react';

import { StoryPoints } from 'daos/model_types';

const isUnsupportedScheme = (storyPoint: StoryPoints, supportedSchemeId: number) => {
  return storyPoint.storyPointScheme.id !== supportedSchemeId;
};

export const buildStoryPointOptions = (storyPoints: ReadonlyArray<StoryPoints>, supportedSchemeId: number) => {
  const storyPointOptions: Array<DropdownItemProps> = [{ key: 0, text: 'None', value: 0 }];

  storyPoints.forEach((sp) => {
    const isArchived = sp.archivedAt !== null;
    const isPreviousScheme = isUnsupportedScheme(sp, supportedSchemeId);
    const isDisabled = isArchived || isPreviousScheme;

    storyPointOptions.push({
      key: sp.id,
      text: sp.value,
      value: sp.id,
      disabled: isDisabled,
      className: classNames('story-point-dropdown__option', {
        'story-point-dropdown__option--previous-scheme': isPreviousScheme,
        'story-point-dropdown__option--archived': isArchived,
      }),
    });
  });

  return storyPointOptions;
};
