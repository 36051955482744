export const PortfolioManagerWavySvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 28" fill="none">
    <rect x="0.521851" y="0.0859375" width="27.7176" height="27.7176" rx="2.77176" fill="#FCCAC6" />
    <path
      d="M5.14148 8.17188H7.45128C8.72694 8.17188 9.76107 9.20601 9.76107 10.4817H5.14148V8.17188Z"
      fill="#1D1D1B"
    />
    <path
      d="M5.14148 12.7891H7.45128C8.72694 12.7891 9.76107 13.8232 9.76107 15.0989H5.14148V12.7891Z"
      fill="#1D1D1B"
    />
    <path
      d="M9.76105 10.4805H16.6904C17.9661 10.4805 19.0002 11.5146 19.0002 12.7903H12.0708C10.7952 12.7903 9.76105 11.7561 9.76105 10.4805Z"
      fill="#1D1D1B"
    />
    <path
      d="M9.76105 15.1016H16.6904C17.9661 15.1016 19.0002 16.1357 19.0002 17.4114H12.0708C10.7952 17.4114 9.76105 16.3772 9.76105 15.1016Z"
      fill="#1D1D1B"
    />
    <path d="M19.0002 12.7891H23.6198V15.0989H21.31C20.0343 15.0989 19.0002 14.0647 19.0002 12.7891Z" fill="#1D1D1B" />
    <path d="M19.0002 17.4102H23.6198V19.72H21.31C20.0343 19.72 19.0002 18.6858 19.0002 17.4102Z" fill="#1D1D1B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3101 7.01683H7.45503V20.8756H21.3101V7.01683ZM7.45128 4.70703C6.17561 4.70703 5.14148 5.74116 5.14148 7.01683V20.8756C5.14148 22.1513 6.17561 23.1854 7.45127 23.1854H21.3101C22.5857 23.1854 23.6199 22.1513 23.6199 20.8756V7.01683C23.6199 5.74116 22.5857 4.70703 21.3101 4.70703H7.45128Z"
      fill="#1D1D1B"
    />
  </svg>
);
